import React from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

interface InputPhoneProps {
  type?: string
  label?: string
  isRequired?: boolean
  placeholder?: string
  name?: string
  value?: string
  setFieldValue?: any
  error?: string
  touched?: boolean
  containerClassName?: string
  labelClassName?: string
  isFormInline?: boolean
  disabled?: boolean
}
const InputPhone = ({
  type = 'text',
  label,
  placeholder,
  isRequired = false,
  name,
  value,
  setFieldValue,
  error,
  touched,
  containerClassName = '',
  labelClassName = '',
  isFormInline,
  disabled,
}: InputPhoneProps) => {
  return (
    <div
      className={`mb-3 input-main  input-main__phone ${containerClassName} ${
        isFormInline ? 'd-flex align-items-center gap-3' : ''
      }`}
    >
      {label && (
        <label htmlFor={label} className={`form-label ${labelClassName}`}>
          {label}
          {isRequired && <span className="required">*</span>}{' '}
        </label>
      )}
      <PhoneInput
        placeholder={placeholder}
        name={name}
        value={value}
        className={` ${error && touched ? 'is-invalid' : ''} `}
        onChange={(e) => setFieldValue(name, e)}
        international
        countryCallingCodeEditable={true}
        disabled={disabled}
      />
      {error && touched && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

export default InputPhone
