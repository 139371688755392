import { useLoggedIn } from './useAuth'

export type AdminPermissionKey =
  | 'no_permission'
  | 'Lessons'
  | 'LMS'
  | 'Trainees'
  | 'Teachers'
  | 'Students'
  | 'Classes'
  | 'Booking'
  | 'Finance'
  | 'Support'
  | 'Sales'
  | 'Marketing'
  | 'Settings'
  | 'User Manage'
  | 'Plans'
  | 'Coupons'
  | 'Categories'
  | 'Support Topics'
  | 'Ranking'
  | 'Reports'
  | 'Orders'
  | 'Payroll'
  | 'Organizations'
  | 'Slots'
  | 'Groups'
  | 'FeedbackTemplates'
  | 'Activity Log'
  | 'Leaves'
  | 'Dashboard'
  | 'Projects'
  | 'Blogs'
  | 'Marketing Leads'
  | 'Blocked Days'
  | 'Export Center'
  | 'Community'
  | 'Trainees'
  | 'Physical Locations'

export function useAdminTabPermissions(permission: AdminPermissionKey) {
  const { loggedInUser } = useLoggedIn()

  if (!loggedInUser.isAdmin)
    return { permissions: [], canEdit: false, readOnly: true }

  const permissions = loggedInUser.permissions?.[permission]

  const canEdit = permissions?.includes?.('write')

  return {
    permissions,
    canEdit,
    readOnly: !canEdit,
  }
}
