import { useLoggedInStudent } from 'auth/useAuth'
import { SignUpSteps } from 'components/Cards/SignUp/steps/SignupSteps'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import ScheduleNextSession from 'components/Modals/ScheduleNextSession'
import { Student } from 'core/types/User'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import { defRoute } from './utils/defRoute'

export const EnrollmentQuizPageStates: Student['state'][] = [
  'enrollment_quiz_failed',
  'enrollment_quiz_inprogess',
  'enrollment_quiz_pending',
  'enrollment_quiz_pending_results',
  'enrollment_quiz_pending_configuration',
]

export const StudentRouteMap = {
  Classes: 'classes',
  Schedule: 'schedule',
  Progress: 'progress',
  'Help Desk': 'help-desk',
  Quiz: 'enrollment-quiz',
  Activities: 'activities',
  Community: 'community',
  'Practical Classes': 'practical-classes',
}

function StudentRouteGuard() {
  const { loggedInStudent } = useLoggedInStudent()
  if (EnrollmentQuizPageStates.includes(loggedInStudent.state)) {
    return <Navigate to="/student/enrollment-quiz" />
  }
  if (loggedInStudent.pending_online_group_selection) {
    return (
      <div className="mt-3">
        <ScheduleNextSession
          isStudentInGroup={loggedInStudent?.organization?.is_whitelabel}
          cardStep={SignUpSteps.Slot_3}
        />
      </div>
    )
  }

  if (loggedInStudent.state === 'pending_session_schedule') {
    return (
      <ScheduleNextSession
        isStudentInGroup={loggedInStudent?.organization?.is_whitelabel}
        cardStep={SignUpSteps.GradeAndLanguage_2}
      />
    )
  }

  return <Outlet />
}

export const StudentRoutes: RouteObject[] = [
  defRoute('classes'),
  {
    path: 'classes',
    element: <StudentRouteGuard />,
    children: [
      {
        path: '',
        // @ts-ignore
        lazy: async () => {
          const { Classes: Component } = await import(
            'pages/student/Classes/Classes'
          )
          return { Component }
        },
        ErrorBoundary,
      },
    ],
  },
  {
    path: 'schedule',
    element: <StudentRouteGuard />,
    children: [
      {
        path: '',
        lazy: async () => {
          const { StudentSchedule: Component } = await import(
            'pages/student/learn/schedule/StudentSchedule'
          )
          return { Component }
        },
        ErrorBoundary,
      },
    ],
  },
  {
    path: 'practical-classes',
    element: <StudentRouteGuard />,
    children: [
      {
        path: '',
        // @ts-ignore
        lazy: async () => {
          const { OfflineClasses: Component } = await import(
            'pages/student/Classes/Classes'
          )
          return { Component }
        },
        ErrorBoundary,
      },
    ],
  },
  {
    path: 'progress',
    element: <StudentRouteGuard />,
    children: [
      {
        path: '',
        lazy: async () => {
          const { Progress: Component } = await import('pages/student/Progress')
          return { Component }
        },
        ErrorBoundary,
      },
    ],
  },
  {
    path: 'activities',
    element: <StudentRouteGuard />,
    children: [
      {
        path: '',
        lazy: async () => {
          const { default: Component } = await import('pages/student/Projects')
          return { Component }
        },
        ErrorBoundary,
      },
    ],
  },
  {
    path: 'subscription',
    lazy: async () => {
      const { default: Component } = await import('pages/student/Subscription')
      return { Component }
    },
    ErrorBoundary,
  },
  {
    path: 'help_desk',
    lazy: async () => {
      const { default: Component } = await import('pages/student/Help')
      return { Component }
    },
    ErrorBoundary,
  },
  {
    path: 'community',
    lazy: async () => {
      const { default: Component } = await import('pages/student/Communities')
      return { Component }
    },
    ErrorBoundary,
  },
  {
    path: 'profile',
    lazy: async () => {
      const { default: Component } = await import('pages/student/Profile')
      return { Component }
    },
    ErrorBoundary,
  },
  {
    path: 'enrollment-quiz',
    lazy: async () => {
      const { EnrollmentQuizPage: Component } = await import(
        'pages/student/EnrollmentQuiz/EnrollmentQuizPage'
      )
      return { Component }
    },
    ErrorBoundary,
  },
  {
    path: 'notifications',
    lazy: async () => {
      const { NotificationsList: Component } = await import(
        'components/Notifications/NotificationsList'
      )
      return { Component }
    },
    ErrorBoundary,
  },
  {
    path: 'reference',
    lazy: async () => {
      const { default: Component } = await import('pages/student/References')
      return { Component }
    },
    ErrorBoundary,
  },
  {
    path: 'content',
    lazy: async () => {
      const { default: Component } = await import(
        'pages/student/learn/content/StudentContent'
      )
      return { Component }
    },
  },
  {
    path: 'content/:levelId',
    async lazy() {
      const { default: Component } = await import(
        'pages/student/learn/content/level-content/LevelContent'
      )
      return { Component }
    },
  },
  {
    path: 'content/learn',
    async lazy() {
      const { default: Component } = await import(
        'pages/student/learn/content/learning-center/LearningPage'
      )
      return { Component }
    },
    ErrorBoundary,
  },
]
