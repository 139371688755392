import Button from 'components/Button/Button'
import { CustomFilter } from 'components/Query/filter'

export const ClassesTeacherStatusFilters: CustomFilter<string | undefined> = {
  pinned: true,
  type: 'custom',
  element: (ctx) => {
    if (ctx.filters?.params.status !== 'today') {
      return null
    }
    const teacherStatus = ctx.filters.params || {}
    const qualifiedClassName = teacherStatus.issue_qualified
      ? 'font-color-gray400'
      : 'font-color-primary200'
    const langClassName = teacherStatus.issue_language
      ? 'font-color-gray400'
      : 'font-color-green500'
    const oooClassName = teacherStatus.issue_ooo
      ? 'font-color-red500'
      : 'font-color-gray400'
    const multiClassName = teacherStatus.issue_conflict
      ? 'font-color-orange500'
      : 'font-color-gray400'

    const handleUpdate = (prop: string) => () => {
      const shouldReset = !!ctx.filters?.params[prop]
      ctx.filters?.update({
        ...ctx.filters.params,
        [prop]: shouldReset ? undefined : true,
      })
    }

    return (
      <div className="d-flex gap-2">
        <Button
          isBorderButton
          tooltip="Qualified"
          className="p-2 border-0 font_20"
          onClick={handleUpdate('issue_qualified')}
        >
          <i className={`fa-solid fa-circle-check ${qualifiedClassName}`} />
        </Button>
        <Button
          isBorderButton
          tooltip="Language Match"
          className="p-2 border-0 font_20"
          onClick={handleUpdate('issue_language')}
        >
          <i className={`fa-solid fa-language ${langClassName}`} />
        </Button>
        <Button
          isBorderButton
          tooltip="Out Of Office"
          className="p-2 border-0 font_20"
          onClick={handleUpdate('issue_ooo')}
        >
          <i
            className={`fa-solid fa-circle-exclamation font-color-red500 ${oooClassName}`}
          />
        </Button>
        <Button
          isBorderButton
          tooltip="Mutli Assigned"
          className="p-2 border-0 font_20"
          onClick={handleUpdate('issue_conflict')}
        >
          <i
            className={`fa-solid fa-triangle-exclamation font-color-orange500 ${multiClassName}`}
          />
        </Button>
      </div>
    )
  },
}
