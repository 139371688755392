import { useLoggedIn } from 'auth/useAuth'
import { useCommunityUnreadStatus } from 'core/hooks/useCommunityUnreadStatus'
import { SideBarNavItem } from 'layout/common/SideBarNavItem'
import { useLocation } from 'react-router-dom'
import { TutorInfo } from './TutorNavLinks'

export function MentorLinks() {
  const { loggedInUser } = useLoggedIn()
  const { hasUnreadMessages } = useCommunityUnreadStatus()
  const location = useLocation()

  return (
    <>
      <TutorInfo />

      <SideBarNavItem
        icon={<i className="fa-solid fa-book-open-reader fa-fw" />}
        to="lms-study"
        text="Study"
      />
      <SideBarNavItem
        icon={<i className="fa fa-leaf" />}
        to={'request'}
        text="Request"
      />

      <SideBarNavItem
        icon={<i className="fa-solid fa-comment" />}
        to={'community'}
        text="Community"
        showIndicator={
          hasUnreadMessages && !location.pathname.includes('community')
        }
      />

      <div className="border-b my-2 border-color-grey-opacity-10" />
      <div className="flex-scroll">
        <SideBarNavItem
          icon={<i className="fa fa-graduation-cap" />}
          to={'classes'}
          text="Classes"
        />
        <SideBarNavItem
          icon={<i className="fa fa-users" />}
          to={'teachers'}
          text="Teachers"
        />
        <SideBarNavItem
          icon={<i className="fa fa-cogs" />}
          to={'lms'}
          text="LMS"
        />

        <SideBarNavItem
          icon={<i className="fa fa-chart-bar" />}
          to={'reports'}
          text="Reports"
        />
        <SideBarNavItem
          icon={<i className="fa fa-upload" />}
          to={'projects'}
          text="Projects"
        />
        <SideBarNavItem
          icon={<i className="fa fa-file-alt" />}
          to={'feedback-templates'}
          text="Templates"
        />
        <SideBarNavItem
          icon={<i className="fa fa-calendar-alt" />}
          to={'leaves'}
          text="Leaves"
        />
      </div>
    </>
  )
}
