import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useLoggedIn } from 'auth/useAuth'
import Button from 'components/Button/Button'
import Icon from 'components/Icon/Icon'
import InputTextArea from 'components/Inputs/InputTextArea'
import { useOpenedModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import { AdminSession } from 'core/types/session'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { QueryKeys } from 'services/QueryKeys'
import { adminSubmitTutorReport } from 'services/admin/sessions'
import { submitTutorReport } from 'services/tutor/sessions'

function useSubmitReport() {
  const queryClient = useQueryClient()
  const { closeModal } = useOpenedModal()
  const { loggedInUser } = useLoggedIn()

  return useMutation({
    mutationFn: loggedInUser.isAdmin
      ? adminSubmitTutorReport
      : submitTutorReport,
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.tutorClasses])
      queryClient.invalidateQueries(['adminSessions'])
      toast.success('Report submitted')
      closeModal()
    },
    onError(e: any) {
      toast.error(e?.response?.data?.message)
    },
  })
}

const ReportModalWindow = ({ session }: { session?: AdminSession }) => {
  const initialValues: {
    internal_feedback: string
  } = {
    internal_feedback: session?.internal_feedback || '',
  }

  const { mutate: SubmitReport } = useSubmitReport()
  const formik = useFormik({
    onSubmit: (form) => {
      session && SubmitReport({ ...form, sessionId: session.id })
    },
    initialValues,
  })

  return (
    <>
      <ModalWindow
        titleIcon={
          <Icon iconName="filter-filled" iconSize={25} className="pe-2" />
        }
        title={'Report'}
        containerClassName="feedback-class-filter-modal"
        maxWidth={552}
        hasCloseBtn
      >
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className="mb-4">
              <InputTextArea
                rows={5}
                name="internal_feedback"
                value={formik.values.internal_feedback}
                onChange={formik.handleChange}
                error={formik.errors.internal_feedback}
                touched={formik.touched.internal_feedback}
                inputClassName="bg-color-primary50-opacity-20 border-0 font_20"
                placeholder="Type here"
              />
            </div>
          </div>

          <div className="d-flex align-items-center mt-4  border-0 justify-content-center tutor-class-filter-modal__actions">
            <Button
              className="font_12 rounded-pill"
              label="Submit"
              type="submit"
            />
          </div>
        </form>
      </ModalWindow>
    </>
  )
}

export default ReportModalWindow
