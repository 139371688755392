import useViewPort from 'core/hooks/useViewPort'
import { ReactNode, useState } from 'react'

interface ArrowSliderProps {
  items: ReactNode[]
  breakpoints?: {
    [key: string]: {
      breakpoint: number
      visibleItems: number
    }
  }
  visibleItems?: number
  children?: ReactNode
  itemClassName?: string
  childrenClassName?: string
  containerClassName?: string
  isShowCurrentRow?: boolean
  nextIcon?: string
  prevIcon?: string
  sliderBtnClassName?: string
}

const ArrowSlider = ({
  items,
  breakpoints = {},
  visibleItems,
  children,
  itemClassName = '',
  childrenClassName = '',
  containerClassName = '',
  isShowCurrentRow = true,
  nextIcon = 'arrow-right-long',
  prevIcon = 'arrow-left-long',
  sliderBtnClassName = '',
}: ArrowSliderProps) => {
  const [currentRow, setCurrentRow] = useState(0)
  const { width } = useViewPort()

  let showItems: any = visibleItems

  Object.keys(breakpoints).forEach((breakpointKey) => {
    const { breakpoint, visibleItems: bpVisibleItems } =
      breakpoints[breakpointKey]
    if (width <= breakpoint) {
      showItems = bpVisibleItems
    }
  })

  const totalRows = Math.ceil(items.length / (showItems || visibleItems))
  const startItemIndex = currentRow * (showItems || visibleItems)
  const endItemIndex = startItemIndex + (showItems || visibleItems)
  const visibleItemsRange = items.slice(startItemIndex, endItemIndex)

  const nextRow = () => {
    const nextRowIndex = currentRow + 1
    setCurrentRow(nextRowIndex < totalRows ? nextRowIndex : 0)
  }

  const prevRow = () => {
    const prevRowIndex = currentRow - 1
    setCurrentRow(prevRowIndex >= 0 ? prevRowIndex : totalRows - 1)
  }

  return (
    <div className={`slider-container ${containerClassName}`}>
      <div className={childrenClassName}>
        {children}

        {visibleItemsRange.length > 0 && (
          <div className={`slider-container-btn ${sliderBtnClassName}`}>
            <button
              type="button"
              className="font_20 font-color-gray300 prev-btn"
              onClick={prevRow}
              disabled={currentRow === 0}
              aria-label="prev-button"
            >
              <i className={`fa-solid fa-${prevIcon} `} />
            </button>
            {isShowCurrentRow && (
              <div className="font_17 font-semibold font-color-gray300">
                <span>{currentRow + 1} / </span>
                <span>{totalRows}</span>
              </div>
            )}
            <button
              type="button"
              className="font_20 font-color-gray300 next-btn"
              onClick={nextRow}
              disabled={currentRow + 1 === totalRows}
              aria-label="next-button"
            >
              <i className={`fa-solid fa-${nextIcon}`} />
            </button>
          </div>
        )}
      </div>

      {visibleItemsRange.map((item, index) => (
        <div key={index} className={`${itemClassName}`}>
          {item}
        </div>
      ))}
    </div>
  )
}

export default ArrowSlider
