import QueryTable from 'components/Query/QueryTable/QueryTable'
import { useResource } from 'components/Query/useResource'
import { useCollection } from 'components/useCollection'
import { ReactNode, isValidElement } from 'react'
import { CommonParams } from 'services/utils'
import { ResourceItem } from 'utils/usePaginatedResource'

export type CellProp = {
  cell: ReactNode
  className?: string
  colSpan?: number
  width?: string
  text?: 'start' | 'center' | 'end'
  align?: 'top' | 'baseline' | 'bottom'
}

export type HeaderProp = {
  header: string
  subheader?: ReactNode
  hidden?: boolean
  colSpan?: number
  width?: string
  className?: string
  text?: 'start' | 'center' | 'end'
  align?: 'top' | 'baseline' | 'bottom'
  sortKey?: string
  sortValue?: string
  index?: number
  onSortClick?: () => void
}

export type TableProps<T extends ResourceItem> = {
  columns: (HeaderProp | string)[]
  fullColSpan?: number
  children: (d: T) => any | ReactNode | ReactNode[] | CellProp[]
  containerClassName?: string
  selectable?: boolean
  singleSelect?: boolean
  onSelectChange?: (d: T[]) => void
  selected?: T[]
  SelectedC?: ReturnType<typeof useCollection<T>>

  expandable?: boolean
  canExpand?: (d: T) => boolean
  expanded?: (d: T) => ReactNode
  canClick?: (d: T) => boolean
  onClick?: (d: T) => void
  selectOnRowClick?: boolean
  placeholder?: ReactNode
  maxHeight?: string
  showPagination?: boolean
} & Partial<ReturnType<typeof useResource<CommonParams, T>>>

export type ViewTableProps<T extends ResourceItem> = Omit<
  TableProps<T>,
  'children' | 'columns'
> &
  Partial<ReturnType<typeof useResource<CommonParams, T>>>

function getCells<T>(
  item: T,
  children: (d: T) => any | ReactNode | ReactNode[] | CellProp[],
): CellProp[] | ReactNode {
  let result: CellProp[] = children(item)

  if (isValidElement(result)) {
    return result as ReactNode
  }

  return result?.map((cell: any) => {
    if (!cell) {
      // if cell is undefined | null | ''
      return { cell: null }
    }
    if (Object.hasOwn(cell, 'cell')) {
      return cell as CellProp
    } else {
      // if cell is a valid react element JSX or string
      return { cell } as CellProp
    }
  })
  // .filter((c: CellProp) => !c?.hidden)
}

function ListToTableCells<T>({
  list,
  cellsRenderer,
  children,
}: {
  list?: T[]
  children: (cells: CellProp[], item: T) => ReactNode
  cellsRenderer: (d: T) => (ReactNode | CellProp)[]
}) {
  return (
    <>
      {list?.map((item) => {
        const cells = getCells(item, cellsRenderer)
        if (isValidElement(cells)) return cells
        else return children(cells as CellProp[], item)
      })}
    </>
  )
}
export default function Table<T extends ResourceItem>(props: TableProps<T>) {
  const query = props

  return <QueryTable query={query} {...props} />
}

function TableData(props: CellProp) {
  const {
    cell,
    align = 'top',
    className,
    colSpan,
    text = 'start',
    width,
  } = props

  return (
    <td
      className={`font-color-blue600 font-normal font_14 text-${text} align-${align} ${className}`}
      colSpan={colSpan}
      width={width}
    >
      {cell}
    </td>
  )
}
