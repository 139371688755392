import Button from 'components/Button/Button'
import { useIsMobile } from 'components/useMediaQueries'
import { useContext } from 'react'
import { isArabic } from 'utils/helpers'
import { IschoolSvgIcon } from './IschoolSvgIcon'
import { SideBarContext } from './NavSidebar'

export function CollapseExpandButton() {
  const { collapsed, setCollapsed } = useContext(SideBarContext)
  const isMobile = useIsMobile()
  return (
    <Button
      isBorderButton
      onClick={(e) => {
        e.stopPropagation()
        setCollapsed((collapsed) => !collapsed)
      }}
      className={`p-0 ${isMobile ? '' : ''} border-color-primary300 rounded
       border-color-grey-opacity-10"`}
      style={{ height: '40px', width: '40px' }}
    >
      {isMobile ? (
        <IschoolSvgIcon />
      ) : collapsed ? (
        <IschoolSvgIcon />
      ) : (
        <i
          className={`fa font-color-blue700 ${
            isArabic() ? 'fa-chevron-right' : 'fa-chevron-left'
          } font_16`}
        />
      )}
    </Button>
  )
}
