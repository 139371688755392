import { useQuery } from '@tanstack/react-query'
import { useLoggedInStudent } from 'auth/useAuth'
import Button from 'components/Button/Button'
import InputSelect from 'components/Inputs/InputSelect'
import { useCreateModal, useOpenedModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import { FormikProvider, useFormik } from 'formik'
import { ChangeEvent } from 'react'
import { toast } from 'react-toastify'
import { getCities } from 'services/public/cities'
import { updateProfile } from 'services/student/profile'
import { Spinner } from 'utils/Spinner'
import * as yup from 'yup'

export function StudentCollectLocationModal() {
  const { loggedInStudent } = useLoggedInStudent()

  const Modal = useCreateModal({
    isOpened:
      false && loggedInStudent.pending_survery_info && !loggedInStudent.form_grade
        ? true
        : false,
  })

  return (
    <Modal.Container>
      <StudentCollectLocationModalWindow />
    </Modal.Container>
  )
}
export function StudentCollectLocationModalWindow() {
  return (
    <div dir="rtl">
      <ModalWindow
        titleClassName="font-semibold "
        maxWidth={700}
        borderRadius={20}
        titleFontSize={18}
        hasCloseBtn
        title={
          <div>
            <div className="text-start d-flex align-items-baseline gap-2 ">
              <i className={`fa-solid fa-bullhorn font-color-orange500`} />
              <div>{`تأكيد موقع حضور الجلسات التطبيقية`}</div>
            </div>
            <div className="font_10 text-right mt-1">
              <ul
                className="list-disc pl-4"
                style={{ textAlignLast: 'right', MozTextAlignLast: 'right' }}
              >
                <li>{`○ يجب الإجابه من قبل ولي الأمر فقط`}</li>
                <li>{`○ يمكن تعديل الإجابات حتى موعد أقصاه السبت 10 أغسطس الساعه 8 م`}</li>
                <li>{`○ لن يتمكن التلميذ من تغيير المحافظة أو المنطقة للجلسات التطبيقية القادمة بعد القيام بهذا التأكيد`}</li>
                <li>{`○ سيتم توزيع التلاميذ في المنطقة التي تم اختيارها دون السماح باختيار المركز المتاح للحضور ضمن المراكز المتوفرة بنفس المنطقة`}</li>
                <li>{`○ سيتم التواصل مرة أخرى لاختيار وتأكيد موعد حضور الجلسات التطبيقية والمخطط عقدها خلال شهر سبتمبر القادم`}</li>
                <li>{`○ سيتم تأكيد موعد ومكان حضور الجلسات التطبيقية القادمة داخل قسم الجلسات التطبيقية في حساب التلميذ على منصة التعلم`}</li>
                <li>{`○ سيتم أيضاً إرسال ايميل قبل الجلسة التطبيقية بــ 48 ساعة على الأقل لتأكيد الموعد والمكان النهائي لحضور الجلسات التطبيقية تفصيلاً`}</li>
              </ul>
            </div>
          </div>
        }
        containerClassName="text-center"
      >
        <LocationGradeQuestionsModal />
      </ModalWindow>
    </div>
  )
}

function useCitiesQuery() {
  return useQuery({
    queryFn: getCities,
    queryKey: ['cities'],
    staleTime: Infinity,
  })
}

const validationSchema = yup.object({
  question1: yup.string().required('Question 1 is required'),
  city: yup.string().required('City is required'),
  area: yup.string().required('Area is required'),
})

export function LocationGradeQuestionsModal() {
  const { closeModal } = useOpenedModal()
  const { loggedInStudent, loadProfile } = useLoggedInStudent()
  const { data: cities } = useCitiesQuery()
  const formik = useFormik({
    initialValues: {
      question1: loggedInStudent.form_grade,
      city: loggedInStudent.form_governorate,
      area: loggedInStudent.form_area,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        await updateProfile({
          form_grade: values.question1,
          form_governorate: values.city,
          form_area: values.area,
        })
        loadProfile()
        closeModal()
      } catch (e: any) {
        toast.error(e?.response?.data?.message || 'An error occurred')
        closeModal()
      }
    },
  })

  const selectedCity = cities?.find((c) => c.city === formik.values.city)

  const handleCityChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const city = e.target.value
    formik.setFieldValue('city', city)
    formik.setFieldValue('area', '')
  }

  const isConfirmButtonDisabled =
    !formik.values.question1 || !formik.values.city || !formik.values.area

  if (!cities) return <Spinner inline />

  return (
    <div className="mt-12 ">
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className=" mt-2 ">
            <div className="d-flex gap-4 my-3">
              <InputSelect
                label="في أي صف دراسي ستكون في العام الدراسي المقبل (2024/2025) ؟ "
                isFormInline={false}
                defaultSelected="العام الدراسي"
                isRequired
                name="question1"
                value={formik.values.question1}
                placeholder="العام الدراسي"
                inputClassName="bg-color-primary50-opacity-20"
                labelClassName="form-label d-flex mb-4  font_16 font-semibold mb-0 font-color-blue700-opacity-100 mb-1 "
                onChange={formik.handleChange}
                values={[
                  {
                    text: 'G4 - الصف الرابع الابتدائي',
                    value: 'G4 - الصف الرابع الابتدائي',
                  },
                  {
                    text: ' G5 - الصف الخامس الابتدائي',
                    value: ' G5 - الصف الخامس الابتدائي',
                  },
                  {
                    text: 'G6 - الصف السادس  الابتدائي',
                    value: 'G6 - الصف السادس  الابتدائي',
                  },
                  {
                    text: 'G7 - الصف الأول الإعدادي',
                    value: 'G7 - الصف الأول الإعدادي',
                  },
                  {
                    text: 'G8 - الصف الثاني الإعدادي',
                    value: 'G8 - الصف الثاني الإعدادي',
                  },
                ]}
              />
            </div>
          </div>
          <span className="d-flex font-semibold mb-4">
            رجاء تأكيد المحافظة والمنطقة الأنسب لحضور الجلسات التطبيقية القادمة
            خلال شهر سبتمبر؟
          </span>
          <div className="my-4">
            <InputSelect
              label="اختار المحافظة"
              defaultSelected="المحافظة"
              isRequired
              name="city"
              placeholder="المحافظة"
              inputClassName="bg-color-primary50-opacity-20"
              labelClassName="form-label d-flex  font_16 font-semibold mb-0 font-color-blue700-opacity-100 mb-1"
              value={formik.values.city}
              onChange={handleCityChange}
              values={cities.map((city) => ({
                text: city.city,
                value: city.city,
              }))}
            />
          </div>
          <div className="my-4">
            <InputSelect
              label="اختار المنطقة"
              defaultSelected="المنطقة"
              isRequired
              name="area"
              placeholder="المنطقة"
              inputClassName="bg-color-primary50-opacity-20"
              labelClassName="form-label d-flex font_16 font-semibold mb-0 font-color-blue700-opacity-100 mb-1"
              value={formik.values.area}
              onChange={formik.handleChange}
              values={selectedCity?.areas?.map((area) => ({
                text: area,
                value: area,
              }))}
              disabled={!formik.values.city}
            />
          </div>
          <Button
            type="submit"
            className="mt-4 font_14 rounded-pill d-inline-flex"
            disabled={isConfirmButtonDisabled}
          >
            تاكيد
          </Button>
        </form>
      </FormikProvider>
    </div>
  )
}
