import { useQueryClient } from '@tanstack/react-query'
import { useLoggedInStudent } from 'auth/useAuth'
import Step3_GradeAndLanguage from 'components/Cards/SignUp/steps/Step3_GradeAndLanguage'
import Step4_Slot from 'components/Cards/SignUp/steps/Step4_Slot'
import { ModalWindow } from 'components/Modal/ModalWindow'
import { useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { createSession } from 'services/student/sessions'
import { errorToast } from 'utils/errorToast'
import { updateGroup } from 'services/student/groups'
import { useTranslation } from 'react-i18next'
import { SignupData } from 'components/Cards/SignUp/SignUpCard'

interface RatingModalProps {
  isStudentInGroup: boolean
  subscriptionId?: number
  onSuccess?: () => void
  cardStep?: number
}

const ScheduleNextSession = ({
  isStudentInGroup,
  subscriptionId,
  onSuccess,
  cardStep = 2,
}: RatingModalProps) => {
  const { loggedInStudent, loadProfile } = useLoggedInStudent()
  const queryClient = useQueryClient()
  const [signupData, setSignupData] = useState<SignupData>({
    grade: loggedInStudent?.grade?.id,
    language: loggedInStudent?.language,
  })
  const [step, setStep] = useState(cardStep)

  const { t } = useTranslation(['common'])

  const bookingOnlyHandler = async (
    bookingId: number,
    grade?: number,
    language?: string,
  ) => {
    try {
      if (isStudentInGroup) {
        await updateGroup(bookingId)
        toast.success('Your group is now booked')
      } else {
        await createSession(bookingId, grade!, language!, subscriptionId)
        toast.success('Your next session is now booked')
        onSuccess && onSuccess()
      }

      loadProfile()
      queryClient.invalidateQueries([])
    } catch (e) {
      errorToast(e)
    }
  }

  return (
    <div>
      <div
        className="card-style"
        style={{ maxWidth: '500px', margin: '0 auto' }}
      >
        <h3 className="font-color-blue700 font_24 font-semibold p-4">
          {t('choose_your_schedule')}
        </h3>
        <div className="card-style p-4">
          {step == 2 && (
            <Step3_GradeAndLanguage
              setStep={setStep}
              signupData={signupData!}
              setSignupData={setSignupData}
            />
          )}

          {step == 3 && (
            <Step4_Slot
              setStep={setStep}
              signupData={signupData!}
              setSignupData={setSignupData}
              IsUserFreeClass={false}
              grade={
                signupData?.grade ||
                loggedInStudent?.grade?.id ||
                loggedInStudent.pending_session_schedule_grade
              }
              language={
                signupData?.language ||
                loggedInStudent?.language ||
                loggedInStudent.pending_session_schedule_language
              }
              bookingOnlyHandler={bookingOnlyHandler}
              isStudentInGroup={isStudentInGroup}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ScheduleNextSession
