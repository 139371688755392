import { createContext, ReactNode, useMemo, useState } from 'react'
type ProviderType = {
  (props: any): JSX.Element
  api?: any
}

export const CollectionContext = createContext<
  ReturnType<typeof useCollection>
>({} as any)
CollectionContext.displayName = 'CollectionContext'

type OptionsType<Item> = {
  enabled?: (item: Item) => boolean
  initial?: Item[]
  onChange?: (items: Item[]) => void
}

type State = { [key: number | string]: { id: number | string } }

export function JSONId(item: any) {
  return JSON.stringify(item)
}

const listToState = (list: { id: number | string }[]) => {
  return list.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})
}

export function useCollection<Item extends { id: number | string }>(
  value: Item[] = [],
  currentPage?: Item[],
  _options?: OptionsType<Item>,
) {
  const options = {
    ..._options,
    enabled: _options?.enabled ? _options.enabled : () => true,
    // getItemKey: _options?.getItemKey || JSONId,
  }

  const { enabled } = options

  const [state, setState] = useState<State>(() => listToState(value))

  const list = Object.values(state) as Item[]

  const setAndReturn = (state: State): State => {
    setState(() => state)
    _options?.onChange && _options.onChange(Object.values(state) as Item[])
    return state
  }

  const reset = (list: Item[]) => {
    return setAndReturn(listToState(list))
  }

  const exists = (item: Item) => {
    return !!state[item.id]
  }

  const removeAll = () => {
    return setAndReturn({})
  }

  const addOne = (item: Item) => {
    return setAndReturn({ ...state, [item.id]: item })
  }

  const addMany = (list: Item[]) => {
    return setAndReturn({ ...state, ...listToState(list) })
  }

  const removeOne = (item: Item) => {
    const { [item.id]: ommited, ...rest } = state

    return setAndReturn(rest)
  }

  const removeMany = (l: Item[]) => {
    const toBeRemoved = l.filter(enabled).map((i) => i.id)
    const newState = listToState(
      Object.values(state).filter((i) => !toBeRemoved.includes(i.id)) as Item[],
    )

    return setAndReturn(newState)
  }

  const toggleItem = (d: Item) => {
    return exists(d) ? removeOne(d) : addOne(d)
  }

  const addOneRemoveAll = (item: Item) => {
    return setAndReturn({ [item.id]: item })
  }

  const toggleItemRemoveAll = (item: Item) => {
    const e = !!exists(item)

    return setAndReturn(e ? {} : { [item.id]: item })
  }

  const addCurrentPage = () => {
    currentPage && addMany(currentPage)
  }

  const removeCurrentPage = () => {
    currentPage && removeMany(currentPage)
  }

  const currentPageExists = () => {
    const allExists = currentPage?.every((i) => exists(i))
    return allExists
  }

  const toggleCurrentPage = () => {
    currentPageExists() ? removeCurrentPage() : addCurrentPage()
  }

  const API = {
    list,
    exists,
    addCurrentPage,
    removeCurrentPage,
    currentPageExists,
    toggleCurrentPage,
    count: list.length,
    toggleItemRemoveAll,
    addOneRemoveAll,
    addOne,
    addMany,
    setState,
    reset,
    removeOne,
    removeMany,
    removeAll,
    enabled,
    toggleItem,
  }

  const Provider: ProviderType = useMemo(() => {
    return function ({ children }: { children: ReactNode }) {
      if (!Provider.api) return <></>

      return (
        <CollectionContext.Provider value={Provider.api}>
          {children}
        </CollectionContext.Provider>
      )
    }
  }, [])

  Provider.api = API
  return { ...API, Provider }
}
