import { MobileNextSessionCounter } from 'components/Cards/ClassesCards/NextClass/NextClassCard'
import ISchoolLogo from 'components/Logo/ISchoolLogo'
import { StudentLinks } from 'components/SideBar'
import { AdminSession } from 'core/types/session'
import { HeaderIconButtons } from 'layout/StudentDashboard/StudentDashboardHeader'
import { CollapseExpandButton } from 'layout/common/CollapseExpandButton'
import LogoutButton from 'layout/common/LogoutButton'
import { SideBarContext } from 'layout/common/NavSidebar'
import { useGetUpcomingOrLastSession } from 'pages/student/Classes/useSessionId'
import { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { WhiteLabelLogo } from './StudentDesktopLayout'
import { StudentCollectLocationModal } from 'components/Modals/StudentCollectLocationModal'
import StudentJoinOfflineSesionModal from 'components/Modals/StudentJoinOfflineSesionModal'

function StudentMobileSidebarMenu() {
  const { collapsed, setCollapsed } = useContext(SideBarContext)

  return (
    <>
      <div
        className={`backdrop bg-color-blue900-opacity-50 ${
          collapsed ? '' : 'active'
        }`}
        onClick={() => setCollapsed(true)}
      />
      <div
        className={`sidebar shadow px-4 py-3 ${collapsed ? 'collapsed' : ''}`}
      >
        <ISchoolLogo useWhiteLogo={false} />
        <WhiteLabelLogo />
        <StudentLinks />
        <div className="mt-4 border-b border-color-grey-opacity-10" />
        <LogoutButton />
      </div>
    </>
  )
}

export default function StudentMobileLayout() {
  const { data } = useGetUpcomingOrLastSession('any')

  return (
    <div
      className="position-relative flex-scroll flex-row overlfow-auto mobile-layout"
      style={{
        height: '100vh',
      }}
    >
      <StudentMobileSidebarMenu />
      <div className="x flex-scroll flex-column">
        <div
          className=" border-b py-2 px-2  border-color-grey-opacity-10 bg-white shadow-sm d-flex  "
          style={{
            maxHeight: '60px',
          }}
        >
          <div className=" px-2">
            <CollapseExpandButton />
          </div>
          {data && <MobileNextSessionCounter session={data as AdminSession} />}
          <HeaderIconButtons />
        </div>
        <StudentJoinOfflineSesionModal />
        <StudentCollectLocationModal />
        <div className="flex-scroll bg-color-primary-300">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
