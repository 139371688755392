import { useLoggedIn } from 'auth/useAuth'
import { QuizAnswerState, i18n } from 'core/types/session'
import { ReactNode } from 'react'
import { isArabic } from 'utils/helpers'

export function QuizQuestion({
  answers,
  questionText = '',
  image,
  onChange,
  disabled,
  value,
  id,
}: {
  value?: i18n
  onChange: (value?: i18n) => void
  id: number
  questionText?: ReactNode
  image?: string
  disabled?: boolean
  answers: { value: i18n; state: QuizAnswerState }[]
}) {
  const { loggedInUser } = useLoggedIn()
  return (
    <>
      {questionText && (
        <p className="font_16 font-light font-color-blue700-opacity-60 mb-3">
          {questionText}
        </p>
      )}

      {image && <img src={image} width={'auto'} height={'200px'} />}
      <div className="class-quiz-modal__answer border-width-1 border-solid border-color-primary100 border-solid border-radius-8 p-2">
        <ul className={`radio-group`}>
          {(answers || [])
            .filter((a) => !!a.value.en)
            .map(({ value: a, state = '' }, index) => {
              // const inputName = name + '_' + index
              return (
                <li
                  key={index}
                  className="form-check d-flex align-items-center"
                >
                  <label className="d-flex items-content-center form-check-label w-100">
                    <input
                      className={`form-check-input form-check-input--${state}`}
                      type="radio"
                      name={id.toString()}
                      disabled={disabled}
                      onClick={() => {
                        onChange && onChange(a)
                      }}
                      readOnly
                      checked={a.en == value?.en}
                    />
                    <span className="form-check-label w-100">
                      {loggedInUser.isStudent ? (
                        <>{isArabic() ? a.ar : a.en}</>
                      ) : (
                        <div>
                          <div>{a.en}</div>
                          <div className="text-right">{a.ar}</div>
                        </div>
                      )}
                    </span>
                  </label>
                </li>
              )
            })}
        </ul>
      </div>
    </>
  )
}
