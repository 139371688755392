import { Feedback } from 'core/types'
import { PaginatedResponse, Response } from 'core/types/Response'
import { AdminSession, Session } from 'core/types/session'

import { AdminAPI, TutorAPI } from 'services/API'
import {
  CommonParams,
  Optional,
  extractResponseData,
  toPaginatedQueryString,
  toPaginatedRequestParams,
} from 'services/utils'

export enum Day {
  Sun = 0,
  Mon = 1,
  Tue = 2,
  Wed = 3,
  Thu = 4,
  Fri = 5,
  Sat = 6,
}

export const DaysToDayId: { [key: string]: Day } = {
  sunday: Day.Sun,
  monday: Day.Mon,
  tuesday: Day.Tue,
  wednesday: Day.Wed,
  thursday: Day.Thu,
  friday: Day.Fri,
  saturday: Day.Sat,
}

export const Days = {
  [Day.Sun]: {
    id: Day.Sun,
    camelCase: 'sunday',
    pascalCase: 'Sunday',
    shortCamel: 'sun',
    shortPascal: 'Sun',
  },
  [Day.Mon]: {
    id: Day.Mon,
    camelCase: 'monday',
    pascalCase: 'Monday',
    shortCamel: 'mon',
    shortPascal: 'Mon',
  },
  [Day.Tue]: {
    id: Day.Tue,
    camelCase: 'tuesday',
    pascalCase: 'Tuesday',
    shortCamel: 'tue',
    shortPascal: 'Tue',
  },
  [Day.Wed]: {
    id: Day.Wed,
    camelCase: 'wednesday',
    pascalCase: 'Wednesday',
    shortCamel: 'wed',
    shortPascal: 'Wed',
  },
  [Day.Thu]: {
    id: Day.Thu,
    camelCase: 'thursday',
    pascalCase: 'Thursday',
    shortCamel: 'thu',
    shortPascal: 'Thu',
  },
  [Day.Fri]: {
    id: Day.Fri,
    camelCase: 'friday',
    pascalCase: 'Friday',
    shortCamel: 'fri',
    shortPascal: 'Fri',
  },
  [Day.Sat]: {
    id: Day.Sat,
    camelCase: 'saturday',
    pascalCase: 'Saturday',
    shortCamel: 'sat',
    shortPascal: 'Sat',
  },
}

export const DaysOptions = Object.values(Days).map((day) => ({
  text: day.pascalCase,
  value: day.id,
}))

export type FeedbackState = 'waiting' | 'done' | 'late'

export type GetSessionsRequestQP = CommonParams & {
  status?: string
  is_offline?: boolean
  session_status?: string
  keyword?: string
  // start_at?: DateRange
  start_at_from?: Optional<Date>
  start_at_to?: Optional<Date>
  lesson?: number
  level?: number
  grade?: number
  start_at_day?: Day
  start_at_hour?: Optional<Date>
  slot_id?: number
  feedback?: FeedbackState
  tutor_id?: number
  is_student_absent?: boolean
  is_free?: boolean
  organization_id?: number
  curriculum?: number
  language?: string
  tutor_join_time?: string
  filterate?: Record<string, boolean>
}

export function getTutorSessions(params: GetSessionsRequestQP) {
  return TutorAPI.get<PaginatedResponse<AdminSession>>(
    `sessions${toPaginatedQueryString(toPaginatedRequestParams(params))}`,
  ).then((r) => r.data)
}

export function getTutorUpcomingSession() {
  return TutorAPI.get<Response<Session>>('sessions/upcoming')
    .then(extractResponseData)
    .catch((e) => {
      return Promise.resolve({ id: null })
    })
}

export function submitStudentFeedback(data: {
  student_feedback: Feedback[]
  student_feedback_comment: string
  is_student_absent: boolean
  sessionId: number
}) {
  const { sessionId, ...body } = data
  return TutorAPI.put<Response<Session>>(
    `sessions/${sessionId}/feedback`,
    body,
  ).then(extractResponseData)
}
export function submitTutorReport(data: {
  internal_feedback: string
  sessionId: number
}) {
  const { sessionId, ...body } = data
  return TutorAPI.put<Response<Session>>(
    `sessions/${sessionId}/feedback`,
    body,
  ).then(extractResponseData)
}


export function joinTutorSession(sessionId: number) {
  return TutorAPI.put(`sessions/${sessionId}/join`)
}

export function joinAdminSession(sessionId: number) {
  return AdminAPI.put(`sessions/${sessionId}/join`)
}

export function pingParent(sessionId: number) {
  return TutorAPI.post(`sessions/${sessionId}/ping_parent`)
}
