import { ReactNode } from 'react'

interface CheckProps {
  id: string | number
  formik?: any
  label?: string | ReactNode
  containerClassName?: string
  formCheckClassName?: string
  justifyContent?: 'center' | 'between' | 'end' | 'start'
  checked?: boolean
  value?: string
  onChange?: (e: React.InputHTMLAttributes<HTMLInputElement>) => void
  inputClassName?: string
  type: 'radio' | 'checkbox'
  size?: number
  name?: string
  readOnly?: boolean
  onClick?: () => void
  disabled?: boolean
}

const Check = ({
  id,
  formik,
  label,
  checked,
  containerClassName = '',
  type,
  value,
  justifyContent = 'center',
  onChange,
  size = 24,
  inputClassName = '',
  name = 'flexRadioDefault',
  formCheckClassName = '',
  onClick,
  readOnly,
  disabled,
}: CheckProps) => {
  return (
    <div
      onClick={onClick}
      className={`radio-group d-flex flex-wrap justify-content-${justifyContent} ${containerClassName}`}
      style={{
        cursor: 'pointer',
      }}
    >
      <div className={`form-check ${formCheckClassName}`}>
        <input
          disabled={disabled}
          className={`form-check-input ${inputClassName}`}
          type={type}
          name={name}
          id={`radio-${id}`}
          checked={checked}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          style={{
            width: `${size}px`,
            height: `${size}px`,
            cursor: 'pointer',
          }}
        />
        {label && (
          <label className="form-check-label" htmlFor={`radio-${id}`}>
            {label}
          </label>
        )}
      </div>
    </div>
  )
}

export default Check
