import { useLoggedIn, useLoggedInTutor } from 'auth/useAuth'
import { useCommunityUnreadStatus } from 'core/hooks/useCommunityUnreadStatus'
import { SideBarNavItem } from 'layout/common/SideBarNavItem'
import { useLocation } from 'react-router-dom'
import { MentorLinks } from './MentorNavLinks'

export function TutorNavLinks() {
  const { loggedInUser } = useLoggedIn()
  const { hasUnreadMessages } = useCommunityUnreadStatus()
  const location = useLocation()

  if (loggedInUser.is_mentor) return <MentorLinks />
  return (
    <>
      <div className="mt-5" />

      <SideBarNavItem
        icon={<i className="fa fa-graduation-cap" />}
        to={'my-classes'}
        text="Classes"
      />

      <TutorInfo />

      <SideBarNavItem
        icon={<i className="fa-solid fa-book-open-reader fa-fw" />}
        to="lms-study"
        text="LMS"
      />

      <SideBarNavItem
        icon={<i className="fa-solid fa-leaf" />}
        to={'request'}
        text="Request"
      />

      <SideBarNavItem
        icon={<i className="fa-solid fa-headset" />}
        to={'help'}
        text="Help"
      />

      <SideBarNavItem
        icon={<i className="fa-solid fa-comment" />}
        to={'community'}
        text="Community"
        showIndicator={
          hasUnreadMessages && !location.pathname.includes('community')
        }
      />
    </>
  )
}

export function TutorInfo() {
  const { loggedInTutor } = useLoggedInTutor()
  return (
    <SideBarNavItem
      tooltip="Profile"
      text={
        <div className="font-semibold text-capitalize font_12 ">
          <span className="line-clamp-1">{loggedInTutor.display_name}</span>
          <span className="d-block d-flex gap-2 font-normal font-color-blue300 font_12">
            {loggedInTutor.t_id}
            <span className="text-nowrap">
              <i className="fa fa-star font-color-orange500 me-1" />
              {loggedInTutor.rating?.toFixed(2)}
            </span>
          </span>
        </div>
      }
      to="profile"
      icon={<span className="icon fa fa-user"></span>}
    />
  )
}
