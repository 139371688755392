import { useQueryClient } from '@tanstack/react-query'
import InputField from 'components/Inputs/InputField'
import InputPhone from 'components/Inputs/InputPhone'
import Switch from 'components/Inputs/Switch'
import { useOpenedModal } from 'components/Modal'
import { Select } from 'components/Select/Select'
import LanguageSelect from 'components/Selectors/LanguageSelect'
import TagSelect from 'components/Selectors/TagSelect'
import { TimezoneSelect } from 'components/Selectors/TimezoneSelect'
import { GradeCurriculumSelect } from 'components/Selectors/curriculum/GradeSelect'
import { PaginatedAdminSelect } from 'components/Selectors/users/AdminSelect'
import useViewPort from 'core/hooks/useViewPort'
import { Response } from 'core/types/Response'
import { Student } from 'core/types/User'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { AdminAPI } from 'services/API'
import { QueryKeys } from 'services/QueryKeys'
import { createStudent, updateStudent } from 'services/admin/students'
import { useOrganization } from 'utils/Providers/OrganizationProvider'
import { errorToast } from 'utils/errorToast'
import * as yup from 'yup'
import { MEDIA_BREAKPOINT_MD } from '../../../constants'
import { ModalWindow } from '../../Modal/ModalWindow'

const validationSchema = yup.object({
  name: yup.string().required('Required'),
  email: yup.string().email().required('Required'),
  phone: yup.string().required('Required'),
  password: yup.string().min(3),
})

const StudentModalWindow = () => {
  const { width } = useViewPort()
  const qc = useQueryClient()
  const { org } = useOrganization()
  const { data, closeModal } = useOpenedModal<Student>()
  const formik = useFormik<
    Partial<
      Student & {
        grade_id?: number
        plan_id?: number
        currency?: string
        sales_owner_id?: number
        retention_owner_id?: number
      }
    >
  >({
    initialValues: {
      ...data,
      grade_id: data?.grade?.id,
      timezone: data?.id ? data.timezone : 'Africa/Cairo',
      sales_owner_id: data?.sales_owner?.id ? data?.sales_owner.id : undefined,
      retention_owner_id: data?.retention_owner?.id
        ? data?.retention_owner.id
        : undefined,
    },
    validationSchema,
    onSubmit: (formData) => {
      ;(formData.id ? updateStudent : createStudent)({
        ...formData,
        organization_id: org?.id,
        email: formData?.email?.toLowerCase(),
      })
        .then((s) => {
          qc.invalidateQueries([QueryKeys.adminStudents])
          toast.success('Student updated')
          closeModal()
          return s
        })
        .catch(errorToast)
    },
  })

  const labelClassName =
    'w-25 text-nowrap font_16 font-semibold font-color-blue600'

  const inlineForm = width >= MEDIA_BREAKPOINT_MD ? true : false

  return (
    <ModalWindow
      title={`${formik.values?.s_id || 'Create Student'}`}
      headerClassName="gradient-header text-center"
      hasCloseBtn
      maxWidth={1132}
      mainBtn="Confirm"
      formId="StudentModalForm"
      mainButtonType="submit"
      mainBtnClassName="py-17 px-71 font_24 my-4"
    >
      <form id="StudentModalForm" onSubmit={formik.handleSubmit}>
        <div className="row mb-3">
          <div className="col-12 col-lg-6">
            <InputField
              isFormInline={inlineForm}
              labelClassName={labelClassName}
              label="Email"
              name="email"
              type="email"
              value={formik.values?.email}
              isRequired
              onChange={formik.handleChange}
              error={formik.errors.email}
              touched={formik.touched.email}
            />
          </div>
          <div className="col-12 col-lg-6">
            <InputField
              isFormInline={inlineForm}
              type="email"
              labelClassName={labelClassName}
              label="Secondary"
              name="secondary_email"
              value={formik.values?.secondary_email}
              onChange={formik.handleChange}
              error={formik.errors.secondary_email}
              touched={formik.touched.secondary_email}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 col-lg-6">
            <InputField
              isFormInline={inlineForm}
              labelClassName={labelClassName}
              label="Name"
              isRequired
              name="name"
              value={formik.values?.name}
              error={formik.errors.name}
              touched={formik.touched.name}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col-12 col-lg-6">
            <InputPhone
              isFormInline={inlineForm}
              labelClassName={labelClassName}
              label="Contact Phone"
              setFieldValue={formik.setFieldValue}
              name="contact_phone"
              value={formik.values.contact_phone}
              isRequired
              error={formik.errors.contact_phone}
              touched={formik.touched.contact_phone}
            />
          </div>
          <div className="col-12 col-lg-6">
            <InputPhone
              isFormInline={inlineForm}
              labelClassName={labelClassName}
              label="Phone"
              setFieldValue={formik.setFieldValue}
              name="phone"
              value={formik.values.phone}
              isRequired
              error={formik.errors.phone}
              touched={formik.touched.phone}
            />
          </div>
          <div className="col-12 col-lg-6">
            <InputField
              isFormInline={inlineForm}
              labelClassName={labelClassName}
              label="Password"
              name="password"
              value={formik.values?.password}
              error={formik.errors.password}
              touched={formik.touched.password}
              onChange={formik.handleChange}
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12 col-lg-6">
            <TimezoneSelect
              labelPlacement="inline"
              value={formik.values.timezone}
              onChange={(tz) => formik.setFieldValue('timezone', tz)}
              // error={formik.errors.timezone}
              // touched={formik.touched.timezone}
            />
          </div>
          <div className="col-12 col-lg-6">
            <PaginatedAdminSelect
              adminRole="is_sales_owner"
              initialValue={formik.values.sales_owner}
              labelPlacement="inline"
              value={formik.values?.sales_owner_id}
              onChange={(sales_owner_id) =>
                formik.setFieldValue('sales_owner_id', sales_owner_id)
              }
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12 col-lg-6">
            <LanguageSelect
              labelPlacement="inline"
              value={formik.values.language}
              onChange={(language) =>
                formik.setFieldValue('language', language)
              }
            />
          </div>
          <div className="col-12 col-lg-6">
            <GradeCurriculumSelect
              labelPlacement="inline"
              value={formik.values.grade_id}
              onChange={(grade) => formik.setFieldValue('grade_id', grade)}
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12 col-lg-6">
            <TagSelect
              labelPlacement="inline"
              value={formik.values.tags}
              onChange={(tags) => formik.setFieldValue('tags', tags)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <InputField
              isFormInline={inlineForm}
              labelClassName={labelClassName}
              label="National ID"
              name="national_id"
              type="number"
              value={formik.values?.national_id}
              onChange={formik.handleChange}
              error={formik.errors.national_id}
              touched={formik.touched.national_id}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-lg-7">
            <PaginatedAdminSelect
              adminRole="is_retention_owner"
              initialValue={formik.values.retention_owner}
              labelPlacement="inline"
              value={formik.values?.retention_owner_id}
              onChange={(retention_owner_id) =>
                formik.setFieldValue('retention_owner_id', retention_owner_id)
              }
            />
          </div>{' '}
          <div className="col-12 col-lg-5">
            <Select
              label="Lost Reason"
              labelPlacement="inline"
              labelClassName={labelClassName}
              value={formik.values?.lost_reason}
              onChange={(e) => formik.setFieldValue('lost_reason', e)}
              options={[
                { label: 'Season', value: 'Season' },
                { label: 'Bad experience', value: 'Bad experience' },
                { label: 'Not interested', value: 'Not interested' },
                { label: 'Budget', value: 'Budget' },
                { label: 'Lost to competitor', value: 'Lost to competitor' },                
              ]}
            />
          </div>
        </div>
        {data?.id && (
          <div className="row align-items-center mb-3">
            <div className="col-12">
              <InputField
                containerClassName="mt-3"
                labelClassName={labelClassName + ' flex-1'}
                label="Next Level Ids"
                isFormInline
                placeholder="Ids Comma seperated"
                value={formik.values.next_level_ids?.toString()}
                onChange={(e) =>
                  formik.setFieldValue(
                    'next_level_ids',
                    e.target.value.split(','),
                  )
                }
              />
            </div>
          </div>
        )}
        <div className="row align-items-center mb-3">
          <div className="col-12 col-lg-4">
            <Switch
              label={formik.values?.active ? 'Active' : 'Inactive'}
              checked={formik.values?.active}
              name="active"
              onChange={formik.handleChange}
            />
          </div>
          <div className="col-12 col-lg-4 mt-3">
            <Switch
              label={
                formik.values?.community_blocked
                  ? 'Community Blocked'
                  : 'Community Unblocked'
              }
              checked={formik.values?.community_blocked}
              name="community_blocked"
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </form>
    </ModalWindow>
  )
}

export function generateStudentAutoLoginLink(student: Student) {
  return AdminAPI.get<Response<{ auto_login_url: string }>>(
    `/students/${student.id}/generate_auto_login_url`,
  ).then((r) => r.data.data.auto_login_url)
}

export default StudentModalWindow
