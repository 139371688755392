import { Select } from 'components/Select/Select'
import { SelectProps } from './types'

export default function EmploymentTypeSelect(props: SelectProps<string>) {
  return (
    <Select
      {...props}
      label={props.label || 'Employment Type'}
      options={[
        {
          label: 'Full Time',
          value: 'full_time',
        },
        {
          label: 'Part Time',
          value: 'part_time',
        },
      ]}
    />
  )
}
