import { StudentCollectLocationModal } from 'components/Modals/StudentCollectLocationModal'
import StudentJoinOfflineSesionModal from 'components/Modals/StudentJoinOfflineSesionModal'
import StudentSessionRatingModal from 'components/Modals/StudentSessionRatingModal'
import { StudentLinks } from 'components/SideBar'
import StudentDashboardHeader from 'layout/StudentDashboard/StudentDashboardHeader'
import { NavSideBar } from 'layout/common/NavSidebar'
import { Outlet } from 'react-router-dom'
import { useOrganization } from 'utils/Providers/OrganizationProvider'
import { isArabic } from 'utils/helpers'

export function WhiteLabelLogo() {
  const { org } = useOrganization()
  if (!org?.is_whitelabel) return <div className="mt-5" />

  return (
    <img
      className="mt-4 mb-4"
      style={{
        width: '100%',
        objectFit: 'cover',
      }}
      alt=""
      src={(isArabic() ? org.logo_ar_url : org.logo_en_url) || org.logo_url}
    />
  )
}

export default function StudentDesktopLayout() {
  return (
    <div className="layout">
      <NavSideBar>
        <WhiteLabelLogo />
        <StudentLinks />
      </NavSideBar>
      <div className="position-relative rounded p-0 flex-scroll bg-color-primary300-opacity-50 flex-scroll">
        <StudentDashboardHeader />

        <div className="flex-scroll">
          <StudentSessionRatingModal />
          <StudentJoinOfflineSesionModal />
          <StudentCollectLocationModal />

          <Outlet />
        </div>
      </div>
    </div>
  )
}
