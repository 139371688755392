import { useState } from 'react'
import RadioGroup from 'components/Inputs/RadioGroup'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'auth/useAuth'
import { useQuery } from '@tanstack/react-query'
import { StudentAPI } from 'services/API'
import { extractResponseData } from 'services/utils'
import { getGrades } from 'services/public/grades'
import { Response } from 'auth/AuthProvider'
import RadioGroupLabels from 'components/Inputs/RadioGroupLabels'

function useGetGrades() {
  const { user } = useAuth()

  return useQuery({
    queryFn: !!user
      ? () =>
          StudentAPI.get<Response<{ id: number; name: string }[]>>(
            'grades',
          ).then(extractResponseData)
      : getGrades,
    queryKey: ['slots'],
  })
}

const Step3_GradeAndLanguage = ({
  setStep,
  signupData,
  setSignupData,
}: any) => {
  const [grade, setGrade] = useState<number | undefined>(signupData?.grade)
  const [language, setLanguage] = useState<string | undefined>(
    signupData?.language,
  )

  const { t } = useTranslation(['common'])

  const { data: grades } = useGetGrades()

  if (!grades) return <></>

  return (
    <div>
      <div className="select-list-style select-list-style__age">
        <p className="select-list-style-title">{t('student_age')}</p>
        <RadioGroupLabels
          values={[
            {
              id: 1,
              text: (
                <span>
                  6-7 <br /> <span className="font-light">{t('years')}</span>
                </span>
              ),
              groupName: 'grade',
              value: grades[0]?.id,
            },
            {
              id: 2,
              text: (
                <span>
                  8-9 <br /> <span className="font-light">{t('years')}</span>
                </span>
              ),
              groupName: 'grade',
              value: grades[1]?.id,
            },
            {
              id: 3,
              text: (
                <span>
                  10-11 <br /> <span className="font-light">{t('years')}</span>
                </span>
              ),
              groupName: 'grade',
              value: grades[2]?.id,
            },
            {
              id: 4,
              text: (
                <span>
                  12-13 <br /> <span className="font-light">{t('years')}</span>
                </span>
              ),
              groupName: 'grade',
              value: grades[3]?.id,
            },
            {
              id: 5,
              text: (
                <span>
                  14-15 <br /> <span className="font-light">{t('years')}</span>
                </span>
              ),
              groupName: 'grade',
              value: grades[4]?.id,
            },
            {
              id: 6,
              text: (
                <span>
                  16-17 <br /> <span className="font-light">{t('years')}</span>
                </span>
              ),
              groupName: 'grade',
              value: grades[5]?.id,
            },
          ]}
          value={grade}
          onChange={setGrade}
        />
      </div>
      <div className="select-list-style select-list-style__lang">
        <p className="select-list-style-title">{t('teaching_language')}</p>
        <RadioGroupLabels
          values={[
            {
              id: 1,
              text: t('english'),
              groupName: 'language',
              value: 'english',
            },
            {
              id: 2,
              text: t('arabic'),
              groupName: 'language',
              value: 'arabic',
            },
          ]}
          value={language}
          onChange={setLanguage}
        />
      </div>
      <button
        className={`${
          !grade || !language ? 'button-disabled-login' : ''
        } button button-color-primary w-100 rounded-pill font_16 font-semibold mt-4 m-auto  button-size-med`}
        onClick={() => {
          setSignupData((prev: any) => {
            return { ...prev, grade, language }
          })
          setStep(3)
        }}
        disabled={!grade || !language}
      >
        {t('book')}
      </button>
    </div>
  )
}

export default Step3_GradeAndLanguage
