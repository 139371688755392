import Tippy from '@tippyjs/react'
import React, { ReactElement, ReactNode } from 'react'
import 'tippy.js/dist/tippy.css'

export type TooltipPlacement = 'top' | 'bottom' | 'right' | 'left'

interface TooltipProps {
  enabled?: boolean
  content: ReactNode
  placement?: TooltipPlacement
  children: ReactElement
  className?: string
}

const Tooltip: React.FC<TooltipProps> = ({
  content,
  placement = 'bottom',
  children,
  enabled = true,
  className = 'custom-tippy',
}) => {
  if (!content || (content && !enabled)) {
    return <>{children}</>
  }

  return (
    <Tippy
      content={content}
      placement={placement}
      className={className}
      duration={[300, 100]}
    >
      {children}
    </Tippy>
  )
}

export default Tooltip
