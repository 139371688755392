import { useQuery } from '@tanstack/react-query'
import { useEffect, useRef } from 'react'
import { getVdoCypherIframeSrc, getVdoCypherOTP } from 'services/vdcypher'

type useLesonOTPQueryProps = {
  videoId?: string
}
function useVdoCypherOTPQuery({ videoId }: useLesonOTPQueryProps) {
  return useQuery({
    queryFn: () => getVdoCypherOTP(videoId!),
    queryKey: ['VdoCypher_otp', videoId],
    keepPreviousData: false,
    enabled: !!videoId,
  })
}

type VdoCypherIframeProps = {
  videoId?: string
  disabled?: boolean
  onPlay?: (player?: any) => void
  onFirstPlay?: () => void
  onVideoEnded?: () => void
}

type VdoPlayer = {
  video: HTMLVideoElement
  api: any // https://www.vdocipher.com/docs/player/v2/api-reference/vdocipher-custom-api
}
function getVdoPlayer(iframe: HTMLIFrameElement | null): VdoPlayer | null {
  if (!iframe) return null
  // @ts-ignore -- VdoPlayer object loaded in index.html and was attached to window
  return window?.['VdoPlayer']?.getInstance(iframe)
}

export function VdoCypherIframe({
  disabled,
  videoId,
  onPlay,
  onVideoEnded,
}: VdoCypherIframeProps) {
  const { data } = useVdoCypherOTPQuery({ videoId })

  const src = getVdoCypherIframeSrc(data)
  const ref = useRef<HTMLIFrameElement>(null)

  const handlePlay = (player: any) => {
    if (onPlay) {
      onPlay(player)
    }
  }

  const onStatusChange = (event: { code: number; label: string }) => {
    if (onVideoEnded && event.label === 'ENDED') {
      onVideoEnded()
    }
  }

  useEffect(() => {
    const player = getVdoPlayer(ref.current)
    if (!player) return

    const play = handlePlay.bind(null, player)

    if (onPlay) {
      player?.video.addEventListener('play', play)
    }

    player.api.addEventListener('statusChange', onStatusChange)
    return () => {
      player?.video.removeEventListener('play', play)
    }
  }, [ref.current])

  return (
    <div
      className="position-relative w-100 h-100 d-flex "
      style={{
        paddingBottom: '56.25%' /* 16:9 Aspect Ratio (9 / 16 * 100) */,
      }}
    >
      {(disabled || !videoId) && (
        <div className="border-0 bg-color-gray400 rounded position-absolute h-100 w-100 top-0 start-0 d-flex align-items-center  justify-content-center">
          <i className="fa fa-play font_30" />
        </div>
      )}
      {!disabled && videoId && (
        <iframe
          ref={ref}
          src={src}
          className="border-0 bg-color-primary300 rounded position-absolute h-100 w-100 top-0 start-0"
          allow="encrypted-media"
          allowFullScreen
        />
      )}
    </div>
  )
}
