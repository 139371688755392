import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
} from 'react'
import { useOrganization } from 'utils/Providers/OrganizationProvider'
import { CollapseExpandButton } from './CollapseExpandButton'
import LogoutButton from './LogoutButton'

export const SideBarContext = createContext<{
  collapsed: boolean
  setCollapsed: Dispatch<SetStateAction<boolean>>
}>({} as any)

export function NavSideBar({ children }: { children: ReactNode }) {
  const { collapsed } = useContext(SideBarContext)
  const { org } = useOrganization()

  return (
    <div
      className={`flex-scroll border  border-grey-opacity-10 shadow-sm  sidebar ${
        collapsed ? 'collapsed' : ''
      }`}
    >
      <div
        className="d-flex justify-content-between align-items-center py-2"
        style={{ width: '100%' }}
      >
        <img
          className="logo-big"
          style={{ width: '60%', objectFit: 'fill' }}
          alt=""
          src={`/images/logo/app/en-a.png`}
        />
        <CollapseExpandButton />
      </div>

      {children}

      <div className="border-b my-2 border-color-grey-opacity-10" />

      <LogoutButton />
    </div>
  )
}
