import Button from 'components/Button/Button'
import Icon from 'components/Icon/Icon'
import { AdminSession } from 'core/types/session'
import { isFuture, subDays } from 'date-fns'
//SCSS

import v from '../../../scss/helpers/_export-theme-variables.scss'

const STATUSCOLORICON: any = {
  done: v.colorgreen600,
  waiting: v.coloryellow500,
  late: v.colorred500,
}

const STATUSICON: any = {
  done: 'check',
  waiting: 'dots',
  late: 'times',
}

const STATUSCOLOR: any = {
  done: 'green600',
  waiting: 'yellow500',
  late: 'red500',
}
export function StudentFeedbackStatus({
  session,
  onFeedbackEdit,
  onFeedbackView,
  readOnly,
  isButtonHidden,
  status,
}: {
  session: AdminSession
  onFeedbackView?: (v: AdminSession) => void
  onFeedbackEdit?: (v: AdminSession) => void
  isButtonHidden?: boolean
  readOnly?: boolean
  status: string
}) {
  const yesterday = subDays(new Date(), 1)
  const sessionEndDate = new Date(session.end_at)
  const isFutureDisabled = isFuture(sessionEndDate)

  const state = status

  return (
    <div>
      <p className={`mb-2 font_14 font-color-${STATUSCOLOR[state]} `}>
        <Icon
          iconName={`circle-${STATUSICON[state]}-filled`}
          iconColor={STATUSCOLORICON[state]}
          iconSize={16}
          className={`me-1 align-middle`}
        />
        {state}
      </p>

      {!isFutureDisabled &&
        onFeedbackEdit &&
        !isButtonHidden &&
        (readOnly ? state === 'done' : true) && (
          <Button
            label={state === 'done' ? 'View' : 'Submit'}
            onClick={() => onFeedbackEdit(session)}
            className="button-border-primary font_14 font-regular"
            buttonSize="xsmall"
          />
        )}
    </div>
  )
}
