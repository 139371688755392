import { useAuth } from 'auth/useAuth'
import { Role } from 'core/types/User'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useOrganization } from 'utils/Providers/OrganizationProvider'

export function getHomePage(role?: Role): string {
  if (role === 'Student') return '/student'
  if (role === 'Tutor') return '/tutor'
  if (role === 'Mentor') return '/mentor'
  if (role === 'Admin') return '/admin'
  return '/login'
}

export function getLoginPage(role?: Role): string {
  if (role === 'Student') return '/login'
  if (role === 'Tutor' || role === 'Mentor') return '/login/tutor'
  if (role === 'Admin') return '/login/admin'
  return '/login'
}

export function GoToHomePage() {
  const { user } = useAuth()
  const location = useLocation()
  const { org } = useOrganization()
  const redirectUrl = org?.student_permissions?.includes("Classes") ? 'classes' : 'schedule'
  const url = user?.role === "Student" ? `${getHomePage(user?.role)}/${redirectUrl}` : getHomePage(user?.role)

  return <Navigate state={{ from: location }} to={url} />
}

export function RequireAuth({ allowed }: { allowed: (Role | 'guest')[] }) {
  const { user, isGuest } = useAuth()

  if (
    (isGuest && allowed.includes('guest')) ||
    (user?.role && allowed.includes(user?.role))
  ) {
    return <Outlet />
  }

  return <GoToHomePage />
}
