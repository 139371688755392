import { useMutation, useQueryClient } from '@tanstack/react-query'
import Icon from 'components/Icon/Icon'
import { useOpenedModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import {
  QuizAnswerState,
  QuizQuesiton,
  Session,
  emptyI18n,
  i18n,
} from 'core/types/session'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { answerQuizQuestion } from 'services/student/sessions'
import { isArabic } from 'utils/helpers'
import { QuizQuestion } from './QuizQuestion'
import { useQuizReducer } from './useQuizReducer'

interface QuizModalProps {
  session?: Session
}

function useSubmitAnswer(session?: Session) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: answerQuizQuestion,
    onSuccess: () => queryClient.invalidateQueries(['sessions', session?.id]),
  })
}

export function ViewQuizAnswersModalWindow() {
  const { closeModal, data: session } = useOpenedModal<NonNullable<Session>>()

  const { t } = useTranslation(['common'])
  const [currentQuestion, setCurrentQuestion] = useState<QuizQuesiton>(
    session!.lesson.questions[0],
  )
  const currentQuestionIndex = session!.lesson.questions.findIndex(
    (q) => q.id === currentQuestion?.id,
  )

  const studentanswer = session!.answers!.find(
    (a) => a.id === currentQuestion.id,
  )!
  const { answer: a, correct_answer: ca } = studentanswer

  const answers: {
    value: i18n
    state: QuizAnswerState
  }[] =
    currentQuestion?.answers.map((c, index) => {
      const choice = JSON.stringify(c)
      const answer = JSON.stringify(a)
      const correct_answer = JSON.stringify(ca)

      if (choice === answer && answer === correct_answer) {
        return {
          value: c,
          state: 'success',
        }
      }

      if (choice === answer && answer !== correct_answer) {
        return {
          value: c,
          state: 'fail',
        }
      }

      if (choice === correct_answer && answer !== correct_answer) {
        return {
          value: c,
          state: 'correction',
        }
      }

      return {
        value: c,
        state: '',
      }
    }) || []

  return (
    <ModalWindow
      containerClassName="class-quiz-modal"
      titleIcon={<Icon iconName="quiz-filled" iconSize={25} className="pe-2" />}
      title={t('quiz')}
      id={'QuizModal'}
      mainBtn={
        currentQuestionIndex + 1 === session?.lesson.questions.length
          ? t('close')
          : t('next')
      }
      mainBtnClassName={`button-color-primary`}
      formId="SubmitQuestionForm"
      mainBtnOnclick={() => {
        if (currentQuestionIndex + 1 === session?.lesson.questions.length) {
          closeModal()
        }
        const nextQuestion = session!.lesson.questions[currentQuestionIndex + 1]
        setCurrentQuestion(nextQuestion)
      }}
      hasCloseBtn
    >
      <h4 className="font_22 font-semibold mb-3">{session?.lesson.name}</h4>

      <QuizQuestion
        id={currentQuestion.id}
        image={currentQuestion.image}
        questionText={
          isArabic() ? currentQuestion.question.ar : currentQuestion.question.en
        }
        answers={answers}
        value={a}
        onChange={(value) => {}}
      />
    </ModalWindow>
  )
}
const QuizModalWindow = () => {
  const { closeModal, data: session } = useOpenedModal<Session>()
  const [quiz, dispatch] = useQuizReducer()

  const { currentQuestion, buttonState, state, answers } = quiz

  const { mutate: SubmitAnswer } = useSubmitAnswer(session)

  const formik = useFormik({
    initialValues: {
      sessionId: session?.id,
      questionId: currentQuestion?.id,
      answer: emptyI18n(),
    },
    onSubmit: (value) => {
      if (state === 'ask_question' && currentQuestion && session) {
        const answer = formik.values.answer

        answer &&
          SubmitAnswer(
            {
              sessionId: session.id,
              questionId: currentQuestion.id,
              answer,
            },
            {
              onSuccess: (response) => {
                const { answer } = response.data.data
                dispatch({
                  type: 'SUBMIT_ANSWER',
                  payload: {
                    correct_answer: answer.correct_answer,
                    answer: answer.answer,
                    questionId: currentQuestion?.id,
                    session,
                  },
                })
              },
            },
          )
      }

      if (state === 'show_correct_answer') {
        dispatch({ type: 'NEXT_ANSWER' })
      }

      if (state === 'quiz_finished') {
        closeModal()
      }
    },
  })

  useEffect(() => {
    if (session) {
      dispatch({ type: 'START_QUIZ', payload: session })
      formik.setFieldValue('sessionId', session.id)
    }
  }, [session?.id])

  useEffect(() => {
    if (currentQuestion) {
      formik.setFieldValue('questionId', currentQuestion?.id)
      formik.setFieldValue('answer', '')
    }
  }, [currentQuestion?.id])

  const { t } = useTranslation(['common'])

  return (
    <ModalWindow
      containerClassName="class-quiz-modal"
      titleIcon={<Icon iconName="quiz-filled" iconSize={25} className="pe-2" />}
      title={t('quiz')}
      id={'QuizModal'}
      mainBtn={t(buttonState.text)}
      mainBtnClassName={`button-color-${buttonState.color}`}
      formId="SubmitQuestionForm"
      mainButtonType="submit"
      hasCloseBtn
    >
      <h4 className="font_22 font-semibold mb-3">{session?.lesson.name}</h4>

      <form id="SubmitQuestionForm" onSubmit={formik.handleSubmit}>
        {currentQuestion && (
          <QuizQuestion
            disabled={state !== 'ask_question'}
            id={currentQuestion.id}
            image={currentQuestion.image}
            questionText={
              isArabic()
                ? currentQuestion.question.ar
                : currentQuestion.question.en
            }
            answers={answers}
            value={formik?.values['answer']}
            onChange={(value) => formik?.setFieldValue('answer', value)}
          />
        )}
      </form>
    </ModalWindow>
  )
}

export default QuizModalWindow
