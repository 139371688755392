import Popup from 'components/Modals/Popup/Poup'
import { useDebouncedState } from 'core/hooks/useDebouncedState'
import { ReactNode, useMemo, useState } from 'react'
import { OptionsList } from './OptionsList'
import { SelectButton } from './SelectButton'
import SelectContainer from './SelectContainer'
import { SelectSearch } from './SelectSearch'
import './styles.scss'
import { NumberOrString, Option, RequiredOption, SelectValue } from './types'
import { usePopup } from './utils/usePopup'

type SingleSelectProps<T extends SelectValue, D> = {
  multi?: false
  renderTrigger?: (
    selected: RequiredOption<NonNullable<NumberOrString>, D>,
  ) => ReactNode
  value?: T
  dataValue?: Option<NonNullable<T>, D>
  options?: (NonNullable<T> | Option<NonNullable<T>, D>)[]
  onChange?: (v?: T) => void
  searchable?: boolean
  onDataChange?: (v: { value?: T; data?: D }) => void
}

type MultiSelectProps<T extends SelectValue, D> = {
  multi: true
  renderSelectedAsPills?: boolean
  renderMultiSelected?: (
    selected: RequiredOption<NonNullable<NumberOrString>, D>[],
  ) => ReactNode

  value?: T[]
  dataValue?: Option<NonNullable<T>, D>
  options?: (NonNullable<T> | Option<NonNullable<T>, D>)[]
  onChange?: (v: T[]) => void
  onDataChange?: (v: { value?: T; data?: D }[]) => void
  searchable?: false
}

type SelectComponentProps<T extends SelectValue, D = undefined> = {
  labelPlacement?: 'inline' | 'block' | 'compact'
  labelClassName?: string
  leftIcon?: ReactNode
  clearable?: boolean
  disabled?: boolean
  searchable?: boolean
  onSearchChange?: (keyword: string) => void
  label?: ReactNode
  isRequired?: boolean
  keepOpen?: boolean
  fixPopupToTriggerWidth?: boolean
  placeholder?: ReactNode
  error?: string
  multi?: boolean
} & (SingleSelectProps<T, D> | MultiSelectProps<T, D>)

function isPrimitive<T extends NumberOrString, D = undefined>(
  s: NumberOrString | Option<T, D>,
) {
  return typeof s === 'string' || typeof s === 'number'
}

function useOptions<T extends NumberOrString, D>(
  _options: (NumberOrString | Option<NonNullable<T>, D>)[],
) {
  return useMemo(
    () =>
      (_options || []).map((o, index) => {
        if (isPrimitive(o)) {
          // options={[1,2,3] || ['x', 'y', 'z']}
          return {
            ['__select_group__']: '',
            id: o,
            disabled: false,
            value: o,
            label: o.toString(),
            searchIn: [o],
            index,
            data: o,
            isSelected: false,
          } as RequiredOption<NonNullable<T>, D>
        } else {
          let option = o as Option<T, D>
          // options={[{value: '', label: <></>, data,....}]}
          return {
            __select_group__: option.group || '',
            id: option.value,
            isSelected: false,
            index,
            disabled: option.disabled,
            value: option.value,
            label: option.label || option.value,
            data: option.data || option.value,
            searchIn: option.searchIn
              ? option.searchIn
              : typeof option.label === 'string'
              ? [option.label, option.value]
              : [option.value],
          } as RequiredOption<NonNullable<T>, D>
        }
      }),
    [_options],
  )
}

function useFilteredOptions<T extends SelectValue, D>(
  options: RequiredOption<NonNullable<T>, D>[],
  search: string = '',
) {
  return useMemo(() => {
    if (search) {
      const lowerCaseSearch = search?.toLowerCase()
      options = options.filter((o) =>
        o.searchIn.some((o) =>
          o?.toString()?.toLowerCase()?.includes(lowerCaseSearch),
        ),
      )
    }

    return options.map((o, index) => ({ ...o, index }))
  }, [options, search])
}

function useWithSelected<T extends SelectValue, D>(
  options: RequiredOption<NonNullable<T>, D>[],
  value?: SelectValue | SelectValue[],
) {
  return useMemo(() => {
    let selected: RequiredOption<NonNullable<T>, D>[] = []
    options = options.map((o) => {
      let isSelected = false
      if (Array.isArray(value)) {
        isSelected = !!value?.includes(o.value)
      } else {
        isSelected = value == o.value
      }

      isSelected && selected.push(o)
      return { ...o, isSelected }
    })
    return { selected, options }
  }, [options, value])
}

export function useDefaults<T extends SelectValue, D>(props: {
  clearable?: boolean
  label?: ReactNode
  labelPlacement?: 'compact' | 'inline' | 'block'
}) {
  let clearable = props.clearable == undefined ? true : props.clearable
  let label = props.label ? props.label : 'Select'
  let labelPlacement = props.labelPlacement ? props.labelPlacement : 'compact'

  return { clearable, label, labelPlacement }
}

export function Select<T extends SelectValue, D>(
  props: SelectComponentProps<T, D>,
) {
  const { label, clearable, labelPlacement } = useDefaults(props)

  const xPopup = usePopup('bottom-left', props.fixPopupToTriggerWidth)

  const [search, setSearch] = useDebouncedState<string>(
    '',
    props.onSearchChange,
  )

  let _options = useOptions(props.options || [])
  _options = useFilteredOptions(_options, search)
  let { selected, options } = useWithSelected(_options, props.value)

  function handleSelect(option?: RequiredOption<any, any>) {
    if (!props.multi) {
      let firstSelected = selected?.[0]
      if (firstSelected?.value !== option?.value) {
        let selectedOption = options.find((o) => option?.value == o.value)
        props.onChange?.(selectedOption?.value as T)
        props.onDataChange?.({
          value: selectedOption?.value as T,
          data: selectedOption?.data,
        })
      }
    } else {
      if (option === undefined) {
        props.onChange?.([])
      } else {
        options = options.map((o) =>
          option?.value == o.value ? { ...o, isSelected: !o.isSelected } : o,
        )
        let output =
          options.filter((o) => o.isSelected).map((o) => o.value as T) || []

        props.onChange?.(output)
      }
    }

    if (!props.keepOpen && !props.multi) {
      setIsOpen(false)
    }
  }

  function handleClear() {
    if (clearable) {
      handleSelect(undefined)
      setIsOpen(false)
      setSearch('')
    }
  }

  const [isOpen, setIsOpen] = useState(false)

  return (
    <Popup
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      renderTrigger={({ setAnchor }) => (
        <SelectContainer
          {...props}
          label={label}
          labelPlacement={labelPlacement}
          isRequired={props.isRequired}
        >
          <SelectButton
            {...props}
            ref={setAnchor}
            label={label}
            labelPlacement={labelPlacement}
            value={selected?.[0]}
            onClear={clearable ? handleClear : undefined}
            onClicK={() => setIsOpen((o) => !o)}
          >
            {selected.length === 0
              ? props.placeholder
              : // Has Selected items
              props.multi
              ? // ---  MULTI SELECT
                //  use custom multi selected render if exists
                props.renderMultiSelected?.(selected) ||
                (props.renderSelectedAsPills ? (
                  <Pills list={selected} />
                ) : selected.length === 1 ? (
                  selected[0].label
                ) : (
                  `${selected.length} Selected`
                ))
              : // ---  SINGLE SELECT
                //  use custom select render if exists
                props.renderTrigger?.(selected[0]) || (
                  <span className="text-capitalize">{selected[0].label}</span>
                )}
          </SelectButton>
        </SelectContainer>
      )}
    >
      {({ anchor }) => (
        <div
          className="shadow border rounded flex-scroll"
          style={{ minWidth: `${anchor?.getBoundingClientRect()?.width}px` }}
        >
          {props.searchable && (
            <SelectSearch value={search} onChange={setSearch} />
          )}
          <div className="flex-scroll rounded ">
            <OptionsList
              multi={props.multi}
              onSelect={handleSelect}
              options={options}
              searchable={props.searchable}
              closePopup={() => setIsOpen(false)}
            />
          </div>
        </div>
      )}
    </Popup>
  )
}

type PillProps = { list: RequiredOption<NonNullable<any>, any>[] }

function Pills({ list }: PillProps) {
  return (
    <div className="d-flex gap-2 items-align-center">
      {list.map((s) => (
        <div
          key={s.value}
          className="rounded py-01 px-2 bg-color-primary300-opacity-50"
        >
          {s.label}
        </div>
      ))}
    </div>
  )
}

export function SelectError(props: { error?: string }) {
  return (
    <>
      {props.error && (
        <span className="font-color-red500 font_12 ps-2 d-flex gap-2 align-items-center">
          {props.error}
        </span>
      )}
    </>
  )
}
