import { PaginatedResponse, Response } from 'core/types/Response'
import { QuizQuestionAnswer, Session, i18n } from 'core/types/session'
import { isAfter } from 'date-fns'
import { BASE_URL_V2, StudentAPI } from 'services/API'
import {
  CommonParams,
  extractResponseData,
  toPaginatedQueryString,
} from 'services/utils'

export function answerQuizQuestion({
  sessionId,
  questionId,
  answer,
}: {
  sessionId: number
  questionId: number
  answer: i18n
}) {
  return StudentAPI.put<Response<{ answer: QuizQuestionAnswer }>>(
    `/sessions/${sessionId}/answer_quiz`,
    {
      sessionId,
      answer: {
        id: questionId,
        answer,
      },
    },
  )
}

function getSesionTypeFilters(type: 'offline' | 'online' | 'any') {
  if (type === 'any') return undefined

  return {
    params: {
      filters: {
        is_offline: type === 'offline',
      },
    },
  }
}

export function getUpcomingOrLastSession(type: 'offline' | 'online' | 'any') {
  return StudentAPI.get<Response<Session>>(
    `${BASE_URL_V2}/student/sessions/upcoming_or_last`,
    getSesionTypeFilters(type),
  ).then((r) => ({
    ...r.data.data,
    is_upcoming: isAfter(new Date(r.data.data.end_at), new Date()),
    is_locked: isAfter(
      new Date(r.data.data.start_at),
      new Date(r.data.data.current_time),
    ),
  }))
}

export function getSessionById(
  session_id: number,
  type: 'offline' | 'online' | 'any',
) {
  return StudentAPI.get<Response<Session>>(
    `${BASE_URL_V2}/student/sessions/${session_id}`,
    getSesionTypeFilters(type),
  ).then((r) => ({
    ...r.data.data,
    is_upcoming: isAfter(new Date(r.data.data.end_at), new Date()),
    is_locked: isAfter(
      new Date(r.data.data.start_at),
      new Date(r.data.data.current_time),
    ),
  }))
}

export function getStudentSessions(params: CommonParams) {
  return StudentAPI.get<PaginatedResponse<Session>>(
    `sessions${toPaginatedQueryString(params)}`,
  ).then(extractResponseData)
}

export function getUpcomingStudentSessions(
  params: CommonParams,
): Promise<Session[]> {
  return StudentAPI.get<PaginatedResponse<Session>>(
    `sessions${toPaginatedQueryString(
      { ...params, filters: { upcoming: true } },
      true,
    )}`,
  ).then((r) => r.data.data.map((s) => ({ ...s, is_upcoming: true })))
}

export function getStudentUpcomingSession() {
  return StudentAPI.get<Response<Session>>('sessions/upcoming')
    .then(extractResponseData)
    .then((s) => ({ ...s, is_upcoming: true }))
    .catch((e) => {
      return Promise.resolve({ id: null })
    })
}

export function submitTutorFeedback(id: number, tutor_feedback: number) {
  return StudentAPI.put<Response<Session>>(`sessions/${id}`, {
    tutor_feedback,
  }).then(extractResponseData)
}

export function createSession(
  booking_id: number,
  grade: number,
  language: string,
  subscription_id?: number,
) {
  return StudentAPI.post<Response<any>>(`/sessions`, {
    booking_id,
    grade,
    language,
    subscription_id,
  })
}

export function joinStudentSession(sessionId: number) {
  return StudentAPI.put(`sessions/${sessionId}/join`)
}

export type StudentTrackActivity = 'resource_view' | 'video_view'
export function trackStudentSession(
  sessionId: number,
  activity: StudentTrackActivity,
) {
  return StudentAPI.put(`sessions/${sessionId}/track`, { activity })
}
