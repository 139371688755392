import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { RouteObject } from 'react-router-dom'

export const TutorLMSRoute: RouteObject = {
  path: 'lms-study',
  children: [
    {
      path: '',
      lazy: async () => {
        const { TeacherLMS: Component } = await import(
          'pages/teacher/LMS/List/index'
        )
        return { Component }
      },
    },
    {
      path: ':studyPlanId',
      lazy: async () => {
        const { StudyLevel: Component } = await import(
          'pages/teacher/LMS/Show/StudyLevel'
        )
        return { Component }
      },
      children: [
        {
          path: '',
          lazy: async () => {
            const { StudyLevelRouteRedirect: Component } = await import(
              'pages/teacher/LMS/Show/StudyLevelRouteRedirect'
            )
            return { Component }
          },
        },
        {
          path: 'intro',
          lazy: async () => {
            const { default: Component } = await import(
              'pages/teacher/LMS/Show/StudyLevelIntro'
            )
            return { Component }
          },
        },
        {
          path: 'lessons/:position',
          lazy: async () => {
            const { default: Component } = await import(
              'pages/teacher/LMS/Show/StudyLesson'
            )
            return { Component }
          },
        },
        {
          path: 'quiz',
          lazy: async () => {
            const { default: Component } = await import(
              'pages/teacher/LMS/Show/Quiz/StudyLevelQuiz'
            )
            return { Component }
          },
        },
      ],

      ErrorBoundary,
    },
  ],
  ErrorBoundary,
}
