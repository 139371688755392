import { AdminRoute } from '.'

export const AdminActivityLogRoute: AdminRoute = {
  permission: 'Activity Log',
  link: 'logs',
  lazy: async () => {
    const { Activities: Component } = await import('pages/admin/Activities')
    return { Component }
  },
}
export const AdminBlogsRoute: AdminRoute = {
  permission: 'Blogs',
  link: 'blogs',
  lazy: async () => {
    const { Blogs: Component } = await import('pages/admin/Blogs')
    return { Component }
  },
}

export const AdminBookingRoute: AdminRoute = {
  permission: 'Booking',
  link: 'booking',
  lazy: async () => {
    const { default: Component } = await import('pages/admin/Booking')
    return { Component }
  },
}

export const AdminCategoriesRoute: AdminRoute = {
  permission: 'Categories',
  link: 'categories',
  lazy: async () => {
    const { Categories: Component } = await import('pages/admin/Categories')
    return { Component }
  },
}

export const AdminClassesRoute: AdminRoute = {
  organization_scoped: true,
  permission: 'Classes',
  link: 'classes',
  lazy: async () => {
    const { default: Component } = await import('pages/admin/Classes_v2')
    return { Component }
  },
}

export const AdminCouponsRoute: AdminRoute = {
  permission: 'Coupons',
  link: 'coupons',
  lazy: async () => {
    const { default: Component } = await import('pages/admin/Coupons')
    return { Component }
  },
}

export const AdminDashboardRoute: AdminRoute = {
  organization_scoped: true,
  permission: 'Dashboard',
  link: 'dashboard',
  lazy: async () => {
    const { default: Component } = await import('pages/admin/Dashboard')
    return { Component }
  },
}

export const AdminExportCenterRoute: AdminRoute = {
  permission: 'Export Center',
  text: 'Export Center',
  link: 'export-center',
  lazy: async () => {
    const { ExportCenter: Component } = await import('pages/admin/ExportCenter')
    return { Component }
  },
}

export const AdminCommunityRoute: AdminRoute = {
  permission: 'Community',
  text: 'Community',
  link: 'community',
  lazy: async () => {
    const { default: Component } = await import('pages/student/Communities')
    return { Component }
  },
}

export const AdminFeedbackTemplatesRoute: AdminRoute = {
  text: 'Feedback Templates',
  permission: 'FeedbackTemplates',
  link: 'feedback-templates',
  lazy: async () => {
    const { FeedbackTemplates: Component } = await import(
      'pages/admin/FeedbackTemplates'
    )
    return { Component }
  },
}

export const AdminFinanceRoute: AdminRoute = {
  permission: 'Finance',
  link: 'finance',
  lazy: async () => {
    const { default: Component } = await import('pages/admin/Finance')
    return { Component }
  },
}

export const AdminGroupsRoute: AdminRoute = {
  organization_scoped: true,
  permission: 'Groups',
  link: 'groups',
  lazy: async () => {
    const { default: Component } = await import('pages/admin/Groups/index_v2')
    return { Component }
  },
}

export const AdminLeavesRoute: AdminRoute = {
  permission: 'Leaves',
  link: 'leaves',
  lazy: async () => {
    const { default: Component } = await import('pages/admin/Leaves')
    return { Component }
  },
}

export const AdminMarketingLeadsRoute: AdminRoute = {
  text: 'Marketing Leads',
  permission: 'Marketing Leads',
  link: 'marketing-leads',
  lazy: async () => {
    const { MarketingLeads: Component } = await import(
      'pages/admin/MarketingLeads'
    )
    return { Component }
  },
}

export const AdminNotificationsRoute: AdminRoute = {
  permission: 'no_permission',
  link: 'notifications',
  lazy: async () => {
    const { AdminNotificationsList: Component } = await import(
      'components/Notifications/NotificationsList'
    )
    return { Component }
  },
}

export const AdminPayrollRoute: AdminRoute = {
  permission: 'Payroll',
  link: 'payroll',
  lazy: async () => {
    const { Payroll: Component } = await import('pages/admin/Payroll')
    return { Component }
  },
}

export const AdminPlansRoute: AdminRoute = {
  permission: 'Plans',
  link: 'plans',
  lazy: async () => {
    const { Plans: Component } = await import('pages/admin/Plans')
    return { Component }
  },
}

export const AdminProjectsRoute: AdminRoute = {
  permission: 'Projects',
  link: 'projects',
  lazy: async () => {
    const { default: Component } = await import('pages/admin/Projects')
    return { Component }
  },
}

export const AdminRankingRoute: AdminRoute = {
  permission: 'Ranking',
  link: 'ranking',
  lazy: async () => {
    const { Ranks: Component } = await import('pages/admin/Ranks')
    return { Component }
  },
}

export const AdminReportsRoute: AdminRoute = {
  permission: 'Reports',
  link: 'reports',
  lazy: async () => {
    const { Reports: Component } = await import('pages/admin/Reports')
    return { Component }
  },
}
export const AdminOrdersRoute: AdminRoute = {
  permission: 'Orders',
  link: 'orders',
  lazy: async () => {
    const { Orders: Component } = await import('pages/admin/Orders')
    return { Component }
  },
}

export const AdminSlotsRoute: AdminRoute = {
  organization_scoped: true,
  permission: 'Slots',
  link: 'slots',
  lazy: async () => {
    const { default: Component } = await import('pages/admin/Slots')
    return { Component }
  },
}

export const AdminStudentsRoute: AdminRoute = {
  organization_scoped: true,
  permission: 'Students',
  link: 'students',
  lazy: async () => {
    const { default: Component } = await import('pages/admin/Students')
    return { Component }
  },
}
export const AdminSupportRoute: AdminRoute = {
  permission: 'Support',
  link: 'support',
  lazy: async () => {
    const { default: Component } = await import('pages/admin/Support')
    return { Component }
  },
}

export const AdminBlockedDaysRoute: AdminRoute = {
  text: 'Blocked Days',
  permission: 'Blocked Days',
  link: 'blocked-days',
  lazy: async () => {
    const { BlockedDays: Component } = await import('pages/admin/BlockedDays')
    return { Component }
  },
}

export const AdminUserManageRoute: AdminRoute = {
  permission: 'User Manage',
  link: 'users-manage',
  lazy: async () => {
    const { default: Component } = await import('pages/admin/UserManage')
    return { Component }
  },
}

export const AdminSupportTopicsRoute: AdminRoute = {
  permission: 'Support Topics',
  link: 'topics',
  lazy: async () => {
    const { SupportTopics: Component } = await import(
      'pages/admin/SupportTopics'
    )
    return { Component }
  },
}

export const AdminPhysicalLocationsRoute: AdminRoute = {
  permission: 'Physical Locations',
  link: 'physical-locations',
  lazy: async () => {
    const { PhysicalLocations: Component } = await import(
      'pages/admin/PhysicalLocations'
    )
    return { Component }
  },
}
