import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useLoggedIn } from 'auth/useAuth'
import Button from 'components/Button/Button'
import { oneDay } from 'components/Cards/ClassesCards/NextClass/useTime'
import InputTextArea from 'components/Inputs/InputTextArea'
import Switch from 'components/Inputs/Switch'
import { useCreateModal, useOpenedModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import { SessionInfo } from 'components/Modals/Admin/Common/SessionInfo'
import { TutorReassignModalWindow } from 'components/Modals/Admin/TutorReassignModalWindow'
import { Response } from 'core/types/Response'
import { AdminSlot } from 'core/types/Slots'
import { AdminSession } from 'core/types/session'
import {
  addMinutes,
  differenceInDays,
  differenceInMinutes,
  format,
  subMinutes,
} from 'date-fns'
import { useOrganization } from 'utils/Providers/OrganizationProvider'

import { useAdminTabPermissions } from 'auth/useAdminTabPermissions'
import { JoinNowButton } from 'components/Cards/ClassesCards/NextClass/TutorNextClassCard'
import DatePickerInput from 'components/Inputs/DatePickerInput'
import FeedbackModalWindow from 'components/Modals/FeedbackModalWindow'
import { QuickLoginButton } from 'components/QuickLogin'
import TopicSelect from 'components/Selectors/TopicSelect'
import Table, { ViewTableProps } from 'components/Table/Table'
import Tooltip from 'components/Tooltip/Tooltip'
import { Quiz } from 'core/types/quiz'
import { StudentFeedbackStatus } from 'pages/teacher/Classes/StudentFeedbackStatus'
import { TutorSessionMaterial } from 'pages/teacher/Classes/TeacherClasses'
import { ReactNode, useState } from 'react'
import { toast } from 'react-toastify'
import { AdminAPI, StudentAPI, TutorAPI } from 'services/API'
import { extractResponseData } from 'services/utils'
import { Spinner } from 'utils/Spinner'
import { formatDate, formatTime } from 'utils/dateUtils'
import { errorToast } from 'utils/errorToast'
import { ViewQuizSubmission } from './StudentTable'
import ReportModalWindow from 'components/Modals/ReportModalWindow'

const Days = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
}

function SlotsMapfn(slots: AdminSlot[]) {
  const list: AdminSlot[] = slots
    .map((slot) => {
      let [hours, minutes] = slot.time_str
        .replace(' AM', '')
        .replace(' PM', '')
        .split(':')
        .map(Number)

      hours += slot.time_str.includes('PM') ? 12 : 0

      return {
        ...slot,
        hours,
        minutes,
        text:
          `${slot.day.charAt(0).toUpperCase()}${slot.day.slice(1)}` +
          ` - ${formatTime(slot.from_time_with_zone, 'hh:mm a')}`,
        day_id: Days[slot.day as 'sunday'],
        timezone: 'Africa/Cairo',
      }
    })
    .sort((a, b) =>
      a.day_id === b.day_id
        ? a.hours === b.hours
          ? a.minutes - b.minutes
          : a.hours - b.hours
        : a.day_id - b.day_id,
    )

  const days = Array.from(new Set(list.map((s) => s.day)))
  const times = Array.from(new Set(list.map((s) => s.time_str)))

  return {
    list,
    days,
    times,
    map: list.reduce((slots, slot) => ({ ...slots, [slot.id]: slot }), {}),
  }
}

function getStudentSlots() {
  return StudentAPI.get<Response<AdminSlot[]>>('sessions/available_slots')
    .then(extractResponseData)
    .then(SlotsMapfn)
}
function getAdminSlots() {
  return AdminAPI.get<Response<AdminSlot[]>>('slots')
    .then(extractResponseData)
    .then(SlotsMapfn)
}

function getTutorSlots() {
  return TutorAPI.get<Response<AdminSlot[]>>('slots')
    .then(extractResponseData)
    .then(SlotsMapfn)
}

export function useGetSlots() {
  const { loggedInUser } = useLoggedIn()
  const queryFn = loggedInUser.isAdmin ? getAdminSlots : getTutorSlots

  return useQuery<{
    map: { [key: number]: AdminSlot }
    list: AdminSlot[]
    times: string[]
    days: string[]
  }>({
    queryFn,
    queryKey: ['slots'],
    staleTime: oneDay,
  })
}

interface freezeModalParams {
  session: AdminSession
  viewOnly: boolean
}

const ClassTable = (
  props: ViewTableProps<AdminSession> & {
    tab: string | undefined
  },
) => {
  const TutorReassignModal = useCreateModal<AdminSession>()
  const FreezeSessionModal = useCreateModal<freezeModalParams>()
  const { org } = useOrganization()
  const { loggedInUser } = useLoggedIn()
  const { data: slots } = useGetSlots()
  const { readOnly, canEdit } = useAdminTabPermissions('Classes')
  const FeedbackModal = useCreateModal<AdminSession>()
  const ReportModal = useCreateModal<AdminSession>()

  const ViewStudentQuizModal = useCreateModal()
  const UnfreezeSessionModal = useCreateModal()
  const JoinTimeEditModal = useCreateModal<AdminSession>()
  const isUpcoming = props.tab === 'upcoming'
  const canSubmitFeedback =
    canEdit || (loggedInUser.isAdmin && loggedInUser.is_edu_mentor)
  return (
    <>
      <ViewStudentQuizModal.Container>
        <SessionQuizModalWindow />
      </ViewStudentQuizModal.Container>
      <Table
        {...props}
        columns={[
          'Student',
          'Schedule',
          'Status',
          'Type',
          'Language',
          'Lesson',
          'Teacher',
          { header: 'Meeting Link', hidden: props.tab !== 'today' },
          { header: 'Quiz', hidden: isUpcoming },
          { header: 'Report', hidden: isUpcoming },
          { header: 'Student Join Time', hidden: isUpcoming, text: 'center' },
          { header: 'Tutor Join Time', hidden: isUpcoming, text: 'center' },
          { header: 'Rate', hidden: isUpcoming },
          { header: 'Attend', hidden: isUpcoming },
          { header: 'Feedback', hidden: isUpcoming },
        ]}
        children={(data) => [
          <>
            <span className="font-color-blue600 font_14 font-normal">
              {data.student.name}
            </span>
            <span className="d-block font-color-blue300 font_12">
              {data.student.s_id} - {data.grade.name}
            </span>

            <QuickLoginButton user={data.student} />
          </>,
          <>
            {' '}
            <span className="font-color-blue600 font_14 font-normal">
              {format(new Date(data.start_at), 'eee d MMM yyyy')}
            </span>
            <span className="d-block font-color-blue300 font_12 mb-1">
              {formatTime(data.start_at, 'hh:mm a')}
            </span>
            {!readOnly && data.status == 'active' && !org?.is_whitelabel && (
              <Button
                disabled={
                  props.tab === 'completed' &&
                  differenceInDays(new Date(), new Date(data.start_at)) > 6
                }
                variant="primary600"
                className=" font_12 mt-1 mb-1 font-normal py-02 px-12"
                label="Freeze"
                isBorderButton
                onClick={() => {
                  FreezeSessionModal.openModal({
                    session: data,
                    viewOnly: false,
                  })
                }}
              />
            )}
            {!readOnly && data.status === 'frozen' && !org?.is_whitelabel && (
              <Button
                disabled={
                  props.tab === 'completed' &&
                  differenceInDays(new Date(), new Date(data.start_at)) > 6
                }
                className=" font_12 mt-1 mb-1 font-normal py-02 px-12"
                variant="orange500"
                label="Unfreeze"
                isBorderButton
                onClick={() => {
                  UnfreezeSessionModal.openModal(data)
                }}
              />
            )}
          </>,
          <div>
            <span className="font-color-blue600 font_14 font-normal">
              {data.status}
              {data.status === 'frozen' && (
                <img
                  src="/images/info.svg"
                  alt="info"
                  width={15}
                  height={15}
                  onClick={() =>
                    FreezeSessionModal.openModal({
                      session: data,
                      viewOnly: true,
                    })
                  }
                  style={{ cursor: 'pointer' }}
                />
              )}

              {data.status === 'canceled' && (
                <img
                  src="/images/canceled.png"
                  alt="canceled"
                  width={15}
                  height={15}
                />
              )}
            </span>
          </div>,
          <>
            <span className="font-color-blue600 font_14 font-normal">
              {data.is_free ? 'Free' : 'Normal'}
            </span>

            {data.student.group_id && (
              <span className="d-block font-color-blue300  font_12 ">
                G-{data.student?.group_id}
              </span>
            )}

            {data.is_offline && (
              <span className="d-block font_12 font-color-blue300 font-normal">
                Offline
              </span>
            )}
          </>,
          <span className="font-color-blue600 font_14 font-normal text-capitalize">
            {data.student.language}
          </span>,
          <>
            <span className="font-color-blue600 font_14 font-normal">
              {data.lesson.name}
            </span>
            <span className="d-block font-color-blue300 font_12">
              {data.level.name}
            </span>
            {loggedInUser.is_mentor && <TutorSessionMaterial session={data} />}
          </>,
          <>
            <div className="font-color-blue600 font_14 font-normal d-flex gap-2 align-items-center">
              {data?.tutor?.name}{' '}
              {data?.tutor?.qualified && (
                <Tooltip content="Qualified">
                  <i className="fa-solid fa-circle-check font_14 font-color-primary200" />
                </Tooltip>
              )}
              {data.tutor.conflict && (
                <Tooltip content="Multi Assigned">
                  <i className="fa-solid fa-triangle-exclamation font_14 font-color-orange500" />
                </Tooltip>
              )}
              {(data.tutor.ooo || !data.tutor.active) && (
                <Tooltip
                  content={data.tutor.ooo ? 'Out of office' : 'Inactive'}
                >
                  <i className="fa-solid fa-circle-exclamation font_14 font-color-red500" />
                </Tooltip>
              )}
              {data.tutor.language_match && (
                <Tooltip content="Language Match">
                  <i className="fa-solid fa-language font_16 font-color-green500" />
                </Tooltip>
              )}
            </div>

            <span className="d-block font-color-blue300 font_12">
              {data.tutor.t_id} - {data.tutor.email}
            </span>

            {!readOnly && data.status == 'active' && !org?.is_whitelabel && (
              <div className="d-flex gap-2 align-items-center">
                <Button
                  // disabled={tab === 'completed'}
                  variant="primary600"
                  className=" font_12 mt-1 mb-1 font-normal py-02 px-12"
                  label={
                    <span className="d-flex gap-2 align-items-center">
                      Reassign{' '}
                      {slots?.map[data.slot_id]?.time_str === undefined && (
                        <span title="Incorrect Assignment " className="m-0">
                          <i className="fa-solid fa-circle-exclamation font_14 font-color-red500" />
                        </span>
                      )}
                    </span>
                  }
                  isBorderButton
                  onClick={() => TutorReassignModal.openModal(data)}
                />
              </div>
            )}
            <QuickLoginButton user={data.tutor} />
          </>,

          <JoinNowButton
            session={data}
            disabled={
              !(
                new Date() >= subMinutes(new Date(data.start_at), 10) &&
                new Date() <= addMinutes(new Date(data.end_at), 10)
              )
            }
          />,

          data.quiz_result && (
            <Button
              variant="primary600"
              className=" font_12 mt-1 mb-1 font-normal py-02 px-12"
              label={`${data.quiz_result.correct_count} / ${data.quiz_result.questions_count}`}
              isBorderButton
              onClick={() => ViewStudentQuizModal.openModal(data)}
            />
          ),
          <div className="classes-tutor-table__feedback text-capitalize text-center">
            <StudentFeedbackStatus
              onFeedbackEdit={ReportModal.openModal}
              session={data}
              status={data.internal_feedback_status}
            />
          </div>,

          <JoinTime
            join_time={data.student_join_time}
            start_at={data.start_at}
          />,
          <JoinTime
            join_time={data.tutor_join_time}
            start_at={data.start_at}
            canEdit={!readOnly}
            onJoinTimeEdit={() => JoinTimeEditModal.openModal(data)}
          />,

          <span className="font-color-blue600 font_14 font-normal">
            {data.tutor_feedback || '-'}
          </span>,
          <span className="font-color-blue600 font_14 font-normal">
            {data.is_student_absent ? 'No' : 'Yes'}
          </span>,
          <StudentFeedbackStatus
            session={data}
            onFeedbackEdit={FeedbackModal.openModal}
            readOnly={!canSubmitFeedback}
            status={data.feedback_status}
          />,
        ]}
      />

      <ReportModal.Container>
        {({ data }) => <ReportModalWindow session={data} />}
      </ReportModal.Container>

      <FeedbackModal.Container>
        {({ data }) => (
          <FeedbackModalWindow session={data} viewOnly={!canSubmitFeedback} />
        )}
      </FeedbackModal.Container>
      <JoinTimeEditModal.Container>
        <JoinTimeEditModalWindow />
      </JoinTimeEditModal.Container>
      <FreezeSessionModal.Container>
        <FreezeSessionModalWindow />
      </FreezeSessionModal.Container>
      <UnfreezeSessionModal.Container>
        <SessionUnfreezeModalWindow />
      </UnfreezeSessionModal.Container>
      <TutorReassignModal.Container>
        <TutorReassignModalWindow />
      </TutorReassignModal.Container>
    </>
  )
}

function JoinTimeEditModalWindow() {
  const { data, closeModal } = useOpenedModal<AdminSession>()

  const start_at = data?.start_at ? new Date(data.start_at) : undefined
  const tutor_join = data?.tutor_join_time
    ? new Date(data.tutor_join_time)
    : undefined
  const [tutor_join_time, setDate] = useState<Date | null>(
    () => tutor_join || start_at || null,
  )

  const [isLoading, setIsLoading] = useState(false)
  const qc = useQueryClient()
  async function handleUpdate() {
    try {
      setIsLoading(true)
      await AdminAPI.put(`sessions/${data?.id}`, { tutor_join_time })
      qc.invalidateQueries(['adminSessions'])
    } catch (e) {
      errorToast(e)
    } finally {
      setIsLoading(false)
      closeModal()
    }
  }

  return (
    <ModalWindow
      title={
        <div className="font_14 font-color-blue300 font-normal">
          Edit{' '}
          <span className="font-color-blue600 font-semibold">
            {data?.tutor.t_id} {data?.tutor.display_name}
          </span>{' '}
          Join Time{' '}
          <div className="font_12 font-color-blue300">
            Session Date {formatDate(start_at, 'MMM dd yyy, h:mm a')}
          </div>
        </div>
      }
      mainBtnOnclick={handleUpdate}
      mainBtn="Confirm"
      mainBtnClassName="px-4 py-2 font_14"
      isMainButtonLoading={isLoading}
      mainButtonDisabled={isLoading}
    >
      <DatePickerInput
        value={tutor_join_time || undefined}
        onChange={setDate}
        placeholderText="Select Date"
        labelClassName="text-nowrap font-bold"
        label="Join Time (Cairo Time)"
        className={`form-control mt-2`}
        dateFormat={'MMM dd yyy, h:mm a'}
        showTimeSelect={true}
        portalId="modals-container"
        showTimeInput
        marginBottom={0}
      />
    </ModalWindow>
  )
}

export function SessionBanner({
  title,
  session,
  rescheduleCount,
  freezeCount,
  children,
}: {
  session?: AdminSession
  date?: Date
  view: 'session' | 'schedule'
  title: ReactNode
  children?: ReactNode
  onSlotIdChange?: (slotId?: number) => void
  rescheduleCount?: number
  freezeCount?: number
}) {
  return (
    <>
      <div className="d-flex gap-2 mb-2">{title}</div>

      <div className="row  card-style px-4 ">
        <div className="col-12 col-lg-4 mb-3 mb-lg-0">
          <span className="d-block font-color-blue300 font_12">Student</span>
          <span className="font-color-blue600 font_14 font-normal">
            {session?.student.name}
          </span>
          <span className="d-block font-color-blue300 font_12">
            {session?.student.s_id} - {session?.grade.name}
          </span>
          {rescheduleCount !== null ? (
            <span className="d-block font-color-blue300 font_12">
              Reschedule Count: {rescheduleCount}
            </span>
          ) : null}
          {freezeCount !== null ? (
            <span className="d-block font-color-blue300 font_12">
              Freeze Count: {freezeCount}
            </span>
          ) : null}
        </div>

        <div className="col-12 col-lg-4 mb-3 mb-lg-0">
          <span className="d-block font-color-blue300 font_12">Teacher</span>
          <span className="font-color-blue600 font_14 font-normal">
            {session?.tutor.name}
          </span>
          <span className="d-block font-color-blue300 font_12">
            T {session?.tutor.t_id}
          </span>
        </div>

        {children}
      </div>
    </>
  )
}

export function SessionUnfreezeModalWindow() {
  const { data, closeModal } = useOpenedModal<AdminSession>()
  const [isLoading, setIsLoading] = useState(false)
  const queryClient = useQueryClient()
  const handleUnfreezeSession = async () => {
    setIsLoading(true)
    try {
      await AdminAPI.put(`/sessions/${data!.id}/unfreeze`)
      toast.success('Session Unfrozen Successfully!')
      queryClient.invalidateQueries(['adminSessions'])
      closeModal()
    } catch (error) {
      errorToast(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ModalWindow
      maxWidth={1000}
      title="Are you sure you want to unfreeze this session ?"
      mainBtn="Confirm"
      mainBtnOnclick={handleUnfreezeSession}
      mainButtonDisabled={isLoading}
    >
      <SessionBanner title="" session={data} view="session" />
    </ModalWindow>
  )
}

export function SessionQuizModalWindow() {
  const { data: session } = useOpenedModal<AdminSession>()
  const { API } = useLoggedIn()

  const { data: quiz } = useQuery({
    keepPreviousData: false,
    queryFn: () =>
      API.get<Response<Quiz>>(`/sessions/${session?.id}/quiz`).then(
        extractResponseData,
      ),
    enabled: !!session?.id,
    queryKey: ['session_quiz', session?.id],
  })

  if (!session || !quiz) return null

  return (
    <ModalWindow
      title={
        <>
          {session.student.s_id} - {session.student.name} -{' '}
          {session?.lesson.name}
        </>
      }
      headerClassName="gradient-header text-center"
      hasCloseBtn
      maxWidth={1132}
      mainBtnClassName="py-17 px-71 font_24 my-4"
    >
      {!quiz && <Spinner />}

      <ViewQuizSubmission {...quiz} />
    </ModalWindow>
  )
}

export function FreezeSessionModalWindow() {
  const { data, closeModal } = useOpenedModal<freezeModalParams>()
  const [useStudentFreezeQutoa, setUseStudentFreezeQutoa] = useState(
    data?.session.in_freeze_quote,
  )
  const [isInPayroll, setIsInPayroll] = useState(data?.session.in_payroll)
  const [notes, setNotes] = useState('')
  const [topic, setTopic] = useState('')
  const queryClient = useQueryClient()

  const handleFreezeSession = async () => {
    try {
      await AdminAPI.put(`sessions/${data?.session?.id}/freeze`, {
        skip_freeze_count: !useStudentFreezeQutoa,
        in_payroll: isInPayroll,
        freeze_notes: notes,
        freeze_topic: topic,
      })

      toast.success('Session Freezed')
      queryClient.invalidateQueries(['adminSessions'])
      closeModal()
    } catch (error) {
      errorToast(error)
    }
  }

  return (
    <ModalWindow
      maxWidth={1000}
      title={
        <SessionBanner
          title={
            <div className="d-flex w-100 align-items-center justify-content-between gap-4">
              Freeze Session
              <label className="ml-auto d-flex  gap-2 align-items-center">
                <span className=" font-color-blue300 font_12">
                  Is Freeze in Tutor Payroll
                </span>
                <Switch
                  checked={isInPayroll}
                  onChange={(e) => setIsInPayroll(e.target.checked)}
                  disabled={data?.viewOnly}
                />
              </label>
              <label className="ml-auto d-flex  gap-2 align-items-center">
                <span className=" font-color-blue300 font_12">
                  Use Student Freeze Quota
                </span>
                <Switch
                  checked={useStudentFreezeQutoa}
                  onChange={(e) => setUseStudentFreezeQutoa(e.target.checked)}
                  disabled={data?.viewOnly}
                />
              </label>
            </div>
          }
          session={data?.session}
          date={new Date(data?.session!.start_at!)}
          view="session"
          rescheduleCount={data?.session?.student?.reschedule_count || 0}
          freezeCount={data?.session?.student?.freeze_count || 0}
        >
          <SessionInfo session={data?.session} />
        </SessionBanner>
      }
    >
      {data?.viewOnly ? (
        <div className="row  card-style px-4 ">
          <div className="col-12 col-lg-4 mb-3 mb-lg-0">
            <span className="d-block font-color-blue300 font_12">
              Freezed By
            </span>
            <span className="font-color-blue600 font_14 font-normal">
              {data?.session?.freezed_by?.name}
            </span>
          </div>
          <div className="col-12 col-lg-4 mb-3 mb-lg-0">
            <span className="d-block font-color-blue300 font_12">
              Description
            </span>
            <span className="d-block font-color-blue300 font_12">
              {data?.session?.freeze_notes}
            </span>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-end">
          <TopicSelect value={topic} onChange={(e) => setTopic(e!)} />
          <InputTextArea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            label="Description"
            labelClassName="text-nowrap justify-content-start"
            containerClassName="mt-3"
            placeholder="Description"
            name="quiz_description"
          />

          <Button
            disabled={!topic}
            className={`rounded-pill mt-2 font_14 font-semibold main-modal__main-btn `}
            onClick={handleFreezeSession}
          >
            Confirm
          </Button>
        </div>
      )}
    </ModalWindow>
  )
}

export function JoinTime({
  join_time,
  start_at,
  canEdit,
  onJoinTimeEdit,
}: {
  join_time: string
  start_at: string
  onJoinTimeEdit?: () => void
  canEdit?: boolean
}) {
  const timeDiff = differenceInMinutes(new Date(start_at), new Date(join_time))
  const isLate = timeDiff < 0

  return (
    <>
      <span
        className={`py-1 ${
          isLate ? 'font-color-red500' : 'font-color-blue300'
        } font_14 font-normal`}
      >
        {join_time ? `${Math.abs(timeDiff)} mins ${isLate ? 'late' : ''}` : '-'}
      </span>
      {canEdit && (
        <div className="mt-2">
          <Button
            onClick={onJoinTimeEdit}
            className="font_12 font-normal  px-2 py-1 d-inline"
            isBorderButton
          >
            Edit
          </Button>
        </div>
      )}
    </>
  )
}

export default ClassTable
