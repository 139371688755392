import { useLoggedIn } from 'auth/useAuth'
import { useEffect } from 'react'
import { Outlet, RouteObject } from 'react-router-dom'
import { AdminRoutes } from './AdminRoutes'
import { TutorCommunityRoute } from './TutorRoutes/TutorCommunityRoute'
import { TutorHelpRoute } from './TutorRoutes/TutorHelpRoute'
import { TutorLMSRoute } from './TutorRoutes/TutorLMSRoute'
import { TutorNotificationRoute } from './TutorRoutes/TutorNotificationRoute'
import { TutorProfileRoute } from './TutorRoutes/TutorProfileRoute'
import { TutorRequestRoute } from './TutorRoutes/TutorRequestRoute'
import { defRoute } from './utils/defRoute'

function TutorMentorSwwitch({ role }: { role: 'Tutor' | 'Mentor' }) {
  const { setCurrentRole } = useLoggedIn()

  useEffect(() => {
    setCurrentRole(role)
  }, [role])

  return <Outlet />
}

export const MentorRoutes: RouteObject[] = [
  defRoute('classes'),
  {
    element: <TutorMentorSwwitch role="Tutor" />,
    children: [
      TutorLMSRoute,
      TutorProfileRoute,
      TutorRequestRoute,
      TutorHelpRoute,
      TutorNotificationRoute,
      TutorCommunityRoute,
    ],
  },
  {
    element: <TutorMentorSwwitch role="Mentor" />,
    children: AdminRoutes,
  },
]
