import { Navigate } from 'react-router-dom'
import { AdminRoute } from 'routes/AdminRoutes'

export const AdminLessonsRoute = {
  permission: 'Lessons',
  organization_scoped: true,
  link: 'curriculum',
  lazy: async () => {
    const { default: Component } = await import('pages/admin/Lessons/index')
    return { Component }
  },
  // element: <AdminLessonsPage />,
  children: [
    {
      path: '',
      lazy: async () => {
        const { RoadMaps: Component } = await import(
          'pages/admin/Lessons/index'
        )
        return { Component }
      },
    },
    {
      path: ':roadMapId/grades',
      lazy: async () => {
        const { RoadMapGrades: Component } = await import(
          'pages/admin/Lessons/RoadMap/Grades'
        )
        return { Component }
      },
    },

    {
      path: ':roadMapId/grades/:gradeId',
      lazy: async () => {
        const { default: Component } = await import(
          'pages/admin/Lessons/Grade/index'
        )
        return { Component }
      },
      children: [
        {
          path: '',
          index: true,
          element: <Navigate to={'levels'} replace />,
        },
        {
          path: 'info',
          lazy: async () => {
            const { default: Component } = await import(
              'pages/admin/Lessons/Grade/Details'
            )
            return { Component }
          },
        },
        {
          path: 'quiz',
          lazy: async () => {
            const { GradeEnrollmentQuiz: Component } = await import(
              'pages/admin/Lessons/Grade/Questions'
            )
            return { Component }
          },
        },
        {
          path: 'levels',
          lazy: async () => {
            const { default: Component } = await import(
              'pages/admin/Lessons/Grade/Levels'
            )
            return { Component }
          },
        },
      ],
    },
    {
      path: ':roadMapId/grades/:gradeId/levels/:levelId',
      lazy: async () => {
        const { default: Component } = await import(
          'pages/admin/Lessons/Level/index'
        )
        return { Component }
      },
      children: [
        {
          path: '',
          index: true,
          element: <Navigate to={'lessons'} replace />,
        },
        {
          path: 'info',
          lazy: async () => {
            const { default: Component } = await import(
              'pages/admin/Lessons/Level/Details'
            )
            return { Component }
          },
        },

        {
          path: 'teachers',
          lazy: async () => {
            const { default: Component } = await import(
              'pages/admin/LMS/AsssignedTutors'
            )
            return { Component }
          },
        },

        {
          path: 'quiz',
          lazy: async () => {
            const { default: Component } = await import(
              'pages/admin/Lessons/Level/Questions'
            )
            return { Component }
          },
        },
        {
          path: 'student-quiz',
          lazy: async () => {
            const { default: Component } = await import(
              'pages/admin/Lessons/Level/StudentQuestions'
            )
            return { Component }
          },
        },

        {
          path: 'lessons',
          lazy: async () => {
            const { default: Component } = await import(
              'pages/admin/Lessons/Level/Lessons'
            )
            return { Component }
          },
        },
      ],
    },
    {
      path: ':roadMapId/grades/:gradeId/levels/:levelId/lessons/:lessonId',
      lazy: async () => {
        const { default: Component } = await import(
          'pages/admin/Lessons/Lesson/index'
        )
        return { Component }
      },
      children: [
        {
          path: '',
          index: true,
          element: <Navigate to={'info'} replace />,
        },
        {
          path: 'info',
          lazy: async () => {
            const { default: Component } = await import(
              'pages/admin/Lessons/Lesson/Details'
            )
            return { Component }
          },
        },
        {
          path: 'quiz',
          lazy: async () => {
            const { default: Component } = await import(
              'pages/admin/Lessons/Lesson/Questions'
            )
            return { Component }
          },
        },
        {
          path: 'topics',
          lazy: async () => {
            const { default: Component } = await import(
              'pages/admin/Lessons/Lesson/Topics'
            )
            return { Component }
          },
        },
      ],
    },
  ],
} as AdminRoute
