import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useLoggedInStudent } from 'auth/useAuth'
import Button from 'components/Button/Button'
import { oneMinute } from 'components/Cards/ClassesCards/NextClass/useTime'
import { useOpenedModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import { useCreateModal } from 'components/Modal/hooks/useCreateModal'
import { Select } from 'components/Select/Select'
import { useIsMobile } from 'components/useMediaQueries'
import { Pagination, Response } from 'core/types/Response'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { StudentAPI } from 'services/API'
import { QueryKeys } from 'services/QueryKeys'
import { extractResponseData } from 'services/utils'
import { formatDate, formatTime } from 'utils/dateUtils'
import { errorToast } from 'utils/errorToast'
import { isArabic } from 'utils/helpers'
import { formatNumber } from 'utils/numbers'

export type OfflineGroup = {
  id: number
  start_at?: string
  group_name: string
  location?: {
    name: string
    address: string
    map_url: string
  }
  following_group: {
    id: number
    start_at: string
    following_slots: OfflineGroup['next_slot'][]
  }
  next_slot: { slot_id?: number; start_at: string; end_at: string }
}

export function useLocalPagination<T>(
  data?: T[],
  per_page = 4,
): Pagination & {
  nextPage: () => void
  prevPage: () => void
  paginatedData: T[]
} {
  const [current, setCurrent] = useState<number>(0)

  if (!data) {
    return {
      paginatedData: [],
      prevPage,
      nextPage,
      count: 0,
      current,
      next: null,
      pages: 0,
      per_page,
      previous: null,
    }
  }
  const pages = Math.ceil(data.length / per_page)
  const next = current < pages - 1 ? current + 1 : null
  const previous = current > 0 ? current - 1 : null

  function nextPage() {
    if (next !== null) {
      setCurrent(next)
    }
  }

  function prevPage() {
    if (previous !== null) {
      setCurrent(previous)
    }
  }
  const startIndex = current * per_page
  const endIndex = startIndex + per_page
  const paginatedData = data.slice(startIndex, endIndex)

  return {
    paginatedData,
    prevPage,
    nextPage,
    count: data.length,
    current,
    next,
    pages,
    per_page,
    previous,
  }
}

export default function StudentJoinOfflineSesionModal() {
  const { loggedInStudent } = useLoggedInStudent()
  const { isFetched } = useGetAvailableOfflineGroups(
    loggedInStudent.pending_offline_group_selection,
  )

  const Modal = useCreateModal({
    isOpened: loggedInStudent.pending_offline_group_selection && isFetched,
    disableBackdrop: true,
  })

  return (
    <Modal.Container>
      <StudentJoinOfflineSesionModalWindow />
    </Modal.Container>
  )
}

export function StudentJoinOfflineSesionModalWindow() {
  const { t } = useTranslation(['common'])
  const { loggedInStudent } = useLoggedInStudent()

  const isMobile = useIsMobile()
  return (
    <ModalWindow
      titleClassName="font-semibold"
      maxWidth={800}
      borderRadius={20}
      bodyClassName={isMobile ? 'p-3' : undefined}
      title={
        <div className="text-start d-flex align-items-baseline gap-4">
          <i
            className={`fa-solid fa-bullhorn font-color-orange500 ${
              isArabic() ? 'mirror' : ''
            }`}
          />
          <div className="">{t('practical_session_details')}</div>
        </div>
      }
      containerClassName="text-center"
      hasCloseBtn
    >
      <div
        dir="auto"
        className="d-flex gap-2 align-items-center font_14 font-color-blue300 font-semibold"
      >
        <i className="fa fa-location-dot font-color-red300 " />
        {loggedInStudent.form_governorate}
        {loggedInStudent.form_area && ` / ${loggedInStudent.form_area}`}
      </div>
      <div className="d-flex">
        <OfflineClassSelector />
      </div>
    </ModalWindow>
  )
}

export function GrayBox({
  text,
  preText,
}: {
  text: ReactNode
  preText?: ReactNode
}) {
  return (
    <div className="font_14 py-2 border d-flex align-items-baseline rounded bg-color-grey-opacity-5  px-2">
      <i className="fa-solid fa-warning font-color-orange500 me-2" />
      <div style={{ whiteSpace: 'pre-wrap' }}>
        {preText} {text}
      </div>
    </div>
  )
}
function useGetAvailableOfflineGroups(enabled = true) {
  return useQuery({
    queryKey: ['offline_groups'],
    queryFn: () =>
      StudentAPI.get<Response<OfflineGroup[]>>('/groups?offline=true').then(
        extractResponseData,
      ),
    enabled,
    staleTime: oneMinute * 5,
  })
}

export function OfflineClassSelector() {
  const { data } = useGetAvailableOfflineGroups()

  const { loadProfile, loggedInStudent } = useLoggedInStudent()

  const Modal = useOpenedModal()
  const { t } = useTranslation(['common'])

  const qc = useQueryClient()

  const [group_id, setGroupId] = useState<number>()
  useEffect(() => {
    data && setGroupId(data[0]?.id)
  }, [data])

  const selectedGroup = data?.find((g) => g.id == group_id)
  const [showInstructions, setShowInstructions] = useState(false)
  const isMobile = useIsMobile()

  const joinGroup = useMutation({
    mutationFn: () =>
      StudentAPI.put(`/groups/${group_id}/join`)
        .then(() => {
          toast.success('Success')
          loadProfile()
          qc.invalidateQueries(['offline_groups'])
          qc.invalidateQueries([QueryKeys.sessionsList])
          qc.invalidateQueries([QueryKeys.sessions])
          Modal?.closeModal()
        })
        .catch(errorToast)
        .finally(() => {
          setShowInstructions(true)
        }),
  })

  if (showInstructions) {
    return (
      <div className="text-start d-grid gap-2 flex-1">
        <h4 className="text-capitalize px-0 m-0 text-start font-color-blue700 font-semibold">
          {t('group')}{' '}
          {formatNumber((data?.findIndex((g) => g.id == group_id) || 0) + 1)}
          <span className="px-4 font_13">
            {formatDate(
              selectedGroup?.next_slot.start_at,
              'EEEE, dd MMMM yyyy - HH:mm',
            )}
          </span>
        </h4>

        <GrayBox
          preText={<span className="font_16">🚙</span>}
          text={t('offline_session_description_1')}
        />

        <GrayBox
          preText={<span className="font_16">⏰</span>}
          text={t('offline_session_description_2')}
        />
        <GrayBox
          preText={<span className="font_16">🖨️</span>}
          text={t('offline_session_description_3')}
        />
        <GrayBox
          preText={<span className="font_15">😊</span>}
          text={t('offline_session_description_4')}
        />

        <div className="d-flex gap-4 font_14 justify-content-center">
          <Button
            onClick={Modal.closeModal}
            disabled={!group_id}
            label={t('confirm')}
            className="rounded-pill gap-2  py-3 font_16 font-semibold mt-2"
          />
        </div>
      </div>
    )
  }

  if (data && data?.length === 0) {
    return (
      <div className="p-2  rounded">
        {isArabic() ? 'لا يوجد مجموعات متاحة' : 'No groups available'}
      </div>
    )
  }

  return (
    <div className="flex-1 flex-scroll">
      <div className={`d-flex flex-wrap gap-2 `}>
        <Select
          label={
            <h6 className="text-capitalize px-0 m-0 text-start font-color-blue700 font-semibold">
              {t('Select Group')}
            </h6>
          }
          labelPlacement="block"
          value={group_id}
          clearable={false}
          onChange={setGroupId}
          options={data?.map((g, index) => ({
            value: g.id,
            label: (
              <div className="text-capitalize d-flex flex-wrap align-items-baseline gap-1">
                <span>{t('group')}</span>
                <span>({formatNumber(index + 1)})</span>
                {' - '}
                {!isMobile && (
                  <span className="text-lower"> {t('starts')} </span>
                )}
                <span className="font-semibold px-2 font-color-primary ">
                  {formatDate(g.next_slot.start_at, 'dd')}
                </span>
                <span className="font-semibold  ">
                  {formatDate(g.next_slot.start_at, 'MMMM')}
                </span>
                <span>{formatDate(g.next_slot.start_at, 'yyyy')}</span>
                <span>{' - '}</span>
                {!isMobile && <span>{t('the_hour')}</span>}
                <span className="font-semibold  ">
                  {formatDate(g.next_slot.start_at, 'h:mm a')}
                </span>
              </div>
            ),
          }))}
        />
      </div>

      {isMobile ? (
        <div>
          <div className="py-2">
            <div className="text-start pt-2 font-color-orange500 font-semibold">
              {t('Semester Two')}
            </div>
            <MobileSlotCard slot={selectedGroup?.next_slot} />
          </div>
          <div className="text-start p-2 font-color-orange500 font-semibold">
            {t('Semester Three')}
          </div>
          {selectedGroup?.following_group?.following_slots.map((g, index) => (
            <MobileSlotCard slot={g} key={g.start_at} />
          ))}
        </div>
      ) : (
        <div className="rounded mt-3   overflow-hidden shadow-sm border border-color-gray200">
          <table className="table table-responsive p-0 m-0 ">
            <thead className="rounded">
              <th className="text-start px-3 bg-color-orange500 py-3 font-color-white">
                {t('Semester')}
              </th>
              <th className="text-start px-3 bg-color-orange500 py-3 font-color-white">
                {t('Day')}
              </th>
              <th className="text-center px-3 bg-color-orange500 py-3 font-color-white">
                {t('From')}
              </th>
              <th className="text-center px-3 bg-color-orange500 py-3 font-color-white">
                {t('To')}
              </th>
              <th
                align="center"
                className="text-center px-3 bg-color-orange500 py-3 font-color-white "
              >
                {t('Session Type')}
              </th>
            </thead>
            <tbody>
              <SlotRow
                slot={selectedGroup?.next_slot}
                showSemesterName={t('Semester Two')}
              />

              {selectedGroup?.following_group?.following_slots.map(
                (g, index) => (
                  <SlotRow
                    slot={g}
                    showSemesterName={index == 0 ? t('Semester Three') : ''}
                    semesterSlotsCount={
                      selectedGroup.following_group.following_slots.length
                    }
                    key={g.start_at}
                  />
                ),
              )}
            </tbody>
          </table>
        </div>
      )}

      {
        <span className="py-2 text-start font-color-blue300">
          <i className="fa fa-warning font-color-orange500 px-2" />
          {t('different_days_warning_message')}
        </span>
      }
      <div className="d-flex gap-4 font_14 justify-content-center">
        <Button
          onClick={() => joinGroup.mutate()}
          disabled={!group_id || joinGroup.isLoading}
          label={t('confirm')}
          isLoading={joinGroup.isLoading}
          className="rounded-pill gap-2  py-3 font_16 font-semibold mt-2"
        />
      </div>
    </div>
  )
}

function SlotRow({
  slot,
  showSemesterName,
  semesterSlotsCount = 1,
}: {
  slot?: OfflineGroup['next_slot']
  showSemesterName?: string
  semesterSlotsCount?: number
}) {
  const { t } = useTranslation(['common'])

  return (
    <>
      <tr key={slot?.start_at} className="font_15">
        {showSemesterName && (
          <td
            className="text-start border-start border-end"
            align="center"
            rowSpan={semesterSlotsCount}
          >
            <div className="d-flex p-2 align-items-center font-color-blue300 p-2 font-semibold font_12">
              {showSemesterName}
            </div>
          </td>
        )}
        <td className="text-start p-2">
          <div className="font-color-blue300 font-semibold">
            {formatDate(slot?.start_at, 'EEEE')}
          </div>
          <div className="font_13 font-color-blue300">
            {formatDate(slot?.start_at, 'dd MMMM yyyy')}
          </div>
        </td>
        <td className="text-start  " align="center">
          <div className="d-flex p-2 justify-content-center align-items-center font_13">
            {formatTime(slot?.start_at)}
          </div>
        </td>
        <td className="text-start ">
          <div className="d-flex p-2 justify-content-center align-items-center font_13">
            {formatTime(slot?.end_at)}
          </div>
        </td>
        <td align="center" className="text-center p-2">
          <div className="d-flex justify-content-center p-2 align-items-center font_13">
            {t('Practical')}
          </div>
        </td>
      </tr>
    </>
  )
}

function MobileSlotCard({ slot }: { slot?: OfflineGroup['next_slot'] }) {
  const { t } = useTranslation(['common'])
  return (
    <div className="text-start border-b px-2 py-2 border-color-grey200 d-grid gap-1">
      <div className="d-flex gap-2 align-items-center">
        <i className="fa-regular fa-circle font_10 font-color-orange500" />
        <div className="font-color-blue300 font-semibold">
          {formatDate(slot?.start_at, 'EEEE')}
        </div>
        <div className="font_13 font-color-blue300">
          {formatDate(slot?.start_at, 'dd MMMM yyyy')}
        </div>
      </div>
      <div className="d-flex align-items-center text-size-small ">
        {t('practical_session')}
      </div>
      <div className="d-flex align-items-center gap-3 text-size-normal">
        <span>{t('From')}</span> <span> {formatTime(slot?.start_at)}</span>
      </div>
      <div className="d-flex align-items-center gap-3 text-size-normal">
        <span>{t('To')}</span> <span>{formatTime(slot?.end_at)}</span>
      </div>
    </div>
  )
}
