import { useLoggedInStudent } from 'auth/useAuth'
import Button from 'components/Button/Button'
import { useCreateModal } from 'components/Modal'
import { StudentJoinOfflineSesionModalWindow } from 'components/Modals/StudentJoinOfflineSesionModal'
import { useClickOutside } from 'components/Select/utils/usePopupPlacement'
import { AdminSession, Session } from 'core/types/session'
import { ReactNode, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'
import { joinStudentSession } from 'services/student/sessions'
import { useOrganization } from 'utils/Providers/OrganizationProvider'
import { cairoTimezone, formatDate } from 'utils/dateUtils'
import { formatNumber } from 'utils/numbers'
import NextClassCountdown from './NextClassCountdown'
import { useNextSessisoServerCountdown } from './useNextSessisonCountdown'

type NextClassCardProps = { session: AdminSession }

export default function NextClassCard({ session }: NextClassCardProps) {
  const nextSessionCountdown = useNextSessisoServerCountdown(session)

  return (
    <UpgradeNowOverlay>
      <div className="mx-auto d-flex gap-3  justify-content-center">
        <SessionDateInfo session={session} />
        <NextClassCountdown {...nextSessionCountdown} />
        <StudentJoinNowButton
          session={session}
          disabled={
            session.is_offline ? false : !nextSessionCountdown.enableJoin
          }
        />
      </div>
    </UpgradeNowOverlay>
  )
}

type JoinButtonProps = { session: AdminSession; disabled?: boolean }

export function StudentJoinNowButton({ session, disabled }: JoinButtonProps) {
  const LocationModal = useCreateModal<Session>()
  const { t } = useTranslation(['common'])

  function handleJoinButton() {
    if (session.is_offline) {
      LocationModal.openModal(session)
    } else {
      joinStudentSession(session.id)
      window.open(session?.meeting_url, '_blank')
    }
  }

  if (session.is_offline) return null

  return (
    <Button
      disabled={disabled}
      isBorderButton={disabled}
      onClick={handleJoinButton}
      label={session.is_offline ? t('get_direction') : t('join_now')}
      buttonSize="small"
      className="join-now text-nowrap border-0 font-bold transition px-3 py-2 rounded gap-2 font_24 font-bold text-nowrap"
    >
      <i className="fa-solid  fa-right-to-bracket" />
      <LocationModal.Container>
        <StudentJoinOfflineSesionModalWindow />
      </LocationModal.Container>
    </Button>
  )
}

export function SessionDateInfo({ session }: NextClassCardProps) {
  const { loggedInStudent } = useLoggedInStudent()
  let timezone = session.is_offline ? cairoTimezone : undefined
  let classDate = formatDate(
    session.start_at,
    'd MMMM yyyy - h:mm a z',
    timezone,
  )
  const { org } = useOrganization()

  const hasClassesAcess = org?.student_permissions?.includes('Classes')

  const { t } = useTranslation(['common'])

  return (
    <NavLink
      className="d-block text-nowrap text-start font-bold d-flex flex-wrap flex-column  font_20 align-items-center justify-content-center"
      to={
        hasClassesAcess
          ? `/student/${session.is_offline ? 'practical-classes' : 'classes'}`
          : '/student/schedule'
      }
      state={{ params: { id: session.id } }}
    >
      <div className="text-capitalize d-flex gap-1 align-items-center">
        <i
          className={
            session.is_offline ? 'fa fa-building-user' : 'fa fa-desktop '
          }
        />
        {session.is_offline
          ? t('second_practical_session')
          : t('your_next_class')}
      </div>
      <span className="font-color-blue300 text-end text-nowrap font_14 font-semibold">
        {classDate}
      </span>
      {session.group && (
        <span className="font-color-blue300 text-end text-nowrap font_14 font-semibold">
          Group ID: {session.group.slug}
        </span>
      )}
    </NavLink>
  )
}
type UpgradeNowOverlayProps = {
  children: ReactNode
}
function UpgradeNowOverlay({ children }: UpgradeNowOverlayProps) {
  const { loggedInStudent } = useLoggedInStudent()
  const { org } = useOrganization()
  let showOverlay = loggedInStudent.should_upgrade && !org?.is_whitelabel

  if (!showOverlay) return <>{children}</>

  return (
    <div className="mx-auto bg-white position-relative">
      {showOverlay && <UpgradeNowButton />}

      <div
        className=" d-flex  gap-3 align-items-center me-auto justify-content-center "
        style={{
          opacity: showOverlay ? '0.16' : '',
          pointerEvents: showOverlay ? 'none' : undefined,
          filter: showOverlay ? ' blur(5px)' : undefined,
        }}
      >
        {children}
      </div>
    </div>
  )
}

function UpgradeNowButton() {
  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column gap-2 h-100 w-100 position-absolute top-0 start-0"
      style={{ zIndex: 1 }}
    >
      <Link to={'subscription'} className="button d-flex flex-lg-column">
        <h6 className="font-semibold text-nowrap d-lg-block d-none font-color-blue300 m-0">
          Your Subscription Ended
        </h6>
        <Button
          variant="orange500"
          className="rounded-pill text-uppercase text-nowrap font_14 py-2 px-3"
        >
          Upgrade Now Or Choose your schedule
        </Button>
      </Link>
    </div>
  )
}

export function MobileNextSessionCounter({ session }: NextClassCardProps) {
  const nextSessionCountdown = useNextSessisoServerCountdown(session)
  const [showNextTimer, setShowTimer] = useState(false)

  const ref = useRef<HTMLDivElement>(null)
  useClickOutside([ref], () => setShowTimer(false))

  return (
    <div ref={ref} className="mx-auto flex-1" style={{ height: '40px' }}>
      <UpgradeNowOverlay>
        <div
          onClick={(e) => {
            e.stopPropagation()
            setShowTimer((show) => !show)
          }}
          className=" cursor-pointer px-3 gap-2 py-05 border border-color-primary100 rounded d-flex justify-content-center  font-bold  "
        >
          <MinimalCount value={nextSessionCountdown.days} text="day" />
          <MinimalCount value={nextSessionCountdown.hours} text="hour" />
          <MinimalCount value={nextSessionCountdown.minutes} text="minute" />
          <i className="fa fa-sort-down   align-items-center font_12  top-0  d-flex" />
        </div>
        {showNextTimer && (
          <div
            className="position-absolute mx-auto start-0 end-0"
            style={{
              zIndex: 1,
              top: '60px',
              width: '90vw',
            }}
          >
            <div className="bg-white d-flex flex-column gap-3 shadow px-1 py-3 border-b">
              <div className="flex-1">
                <SessionDateInfo session={session} />
              </div>
              <NextClassCountdown {...nextSessionCountdown} />
              <StudentJoinNowButton
                session={session}
                disabled={
                  session.is_offline ? false : !nextSessionCountdown.enableJoin
                }
              />
            </div>
          </div>
        )}
      </UpgradeNowOverlay>
    </div>
  )
}

type MinimalCountProps = { value: number; text: string }

function MinimalCount({ value, text }: MinimalCountProps) {
  const { t } = useTranslation(['common'])

  return (
    <span className="text-center">
      <span className="font-color-primary px-05">{formatNumber(value)}</span>
      <span className="font-color-blue300 font_10 ">{t(text)}</span>
    </span>
  )
}
