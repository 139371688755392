export enum QueryKeys {
  enrollmentQuizQuestions = 'enrollmentQuizQuestions',
  issueCertificateQuizQuestions = 'issueCertificateQuizQuestions',
  sessions = 'sessions',
  sessionsSelect = 'sessionsSelect',
  sessionsList = 'SessionsList',
  upcomingSession = 'UpcomingSession',
  upcomingOrLastSession = 'UpcomingOrLastSession',
  learningProgress = 'LearningProgress',
  adminCurriculum = 'adminCurriculum',
  adminStudents = 'adminStudents',
  communityMembers = 'communityMembers',
  adminPayroll = 'adminPayroll',
  tutorClasses = 'tutorClasses',
  tutorCurriculum = 'tutorCurriculum',
  projects = 'projects',
  presentations = 'presentations',
  tutorSchedule = 'tutorSchedule',
  tutorRequests = 'tutorRequests',
  tutorPolicies = 'tutorPolicies',
  adminTutors = 'adminTutors',
  adminNotAssignedTutors = 'adminNotAssignedTutors',
  adminStudyPlans = 'adminStudyPlans',
  adminLevels = 'adminLevels',
  communities = 'Communities',

  organizationsList = 'organizationsList',
  organization = 'organization',
  groupsList = 'groupsList',
  feedbackTemplatesList = 'feedbackTemplatesList',
  studentsStatistics = 'students_statistics',
  publicProjects = 'publicProjects',
  physicalLocations = "physicalLocations"
}
