import { ActionsDropdown } from 'components/Admin/HeaderPage_v2'
import Button from 'components/Button/Button'
import { useDebouncedState } from 'core/hooks/useDebouncedState'
import { Pagination } from 'core/types/Response'
import { useTranslation } from 'react-i18next'
import { isArabic } from 'utils/helpers'
import { formatNumber } from 'utils/numbers'

const NULL_PAGE = 0

function getPaginationElements(
  totalPagesCount: number,
  currentPage: number,
  windowSize = 5,
) {
  const pages = Array.from({ length: totalPagesCount }).map((_, i) => i + 1)

  if (pages.length <= windowSize + 2) {
    return pages
  }

  if (currentPage >= 1 && currentPage <= windowSize - 1) {
    return pages.slice(0, windowSize + 2).concat(...[NULL_PAGE, pages.length])
  }

  if (currentPage >= pages.length - windowSize + 2) {
    return [1, NULL_PAGE].concat(
      pages.slice(pages.length - windowSize - 2, pages.length),
    )
  }

  return [1, NULL_PAGE].concat(
    pages
      .slice(currentPage - windowSize + 2, currentPage + 2)
      .concat(...[NULL_PAGE, pages.length]),
  )
}

export function XPagination({
  pagination,
  onPageChange,
  containerClassName,
  minimal,
}: {
  minimal?: boolean
  onPageChange: (pageNumber: number) => void
  pagination: Pagination
  containerClassName?: string
}) {
  const [current, onChange] = useDebouncedState(
    pagination.current,
    onPageChange,
  )

  const totalPagesCount = Math.ceil(pagination.count / pagination.per_page)
  const buttons = getPaginationElements(totalPagesCount, current)

  const nextDisabled = !pagination.next || totalPagesCount === current
  const prevDisabled = !pagination.previous || current === 1

  return (
    <div
      className={` ${containerClassName} d-flex flex-wrap justify-content-between align-items-center px-2`}
    >
      <div className="text-nowrap font_14 font-color-blue600  px-2 py-4">
        {!minimal && (
          <>
            {(pagination.current - 1) * pagination.per_page + 1} -{' '}
            {Math.min(
              pagination.current * pagination.per_page,
              pagination.count,
            )}{' '}
            of{' '}
          </>
        )}
        {pagination.count} items
      </div>
      <div className="pagination-main">
        <button
          type="button"
          disabled={prevDisabled}
          onClick={() => onChange(current - 1)}
          className={`previous ${prevDisabled ? 'disabled' : ''}`}
          title="Prev"
        >
          <i className="fa-solid fa-arrow-left-long" />
        </button>

        {minimal && (
          <button
            type="button"
            disabled
            className={'active disabled font_12 font-color-blue600'}
            style={{ backgroundColor: 'white', color: 'grey' }}
          >
            {current}
          </button>
        )}
        {!minimal &&
          buttons.map((page, index) => (
            <button
              key={index}
              type="button"
              disabled={current === page || page === NULL_PAGE}
              onClick={() => onChange(page)}
              className={`${current === page ? 'active' : ''} ${
                current === page || page === NULL_PAGE ? 'disabled' : ''
              }`}
            >
              {page === NULL_PAGE ? (
                <i className="fa-solid fa-ellipsis" />
              ) : (
                page
              )}
            </button>
          ))}

        <button
          type="button"
          disabled={nextDisabled}
          onClick={() => onChange(current + 1)}
          className={`next ${nextDisabled ? 'disabled' : ''}`}
          title="Next"
        >
          <i className="fa-solid fa-arrow-right-long" />
        </button>
      </div>
    </div>
  )
}

export function YPagination({
  pagination,
  onPageChange,
  containerClassName,
  minimal,
}: {
  minimal?: boolean
  onPageChange: (pageNumber: number) => void
  pagination: Pagination
  containerClassName?: string
}) {
  const [current, onChange] = useDebouncedState(
    pagination.current,
    onPageChange,
  )

  const totalPagesCount = Math.ceil(pagination.count / pagination.per_page)

  const nextDisabled = !pagination.next || totalPagesCount === current
  const prevDisabled = !pagination.previous || current === 1
  const { t } = useTranslation(['common'])
  return (
    <div
      className={` ${containerClassName} d-flex  font_12 font-semibold font-color-blue300 gap-2 align-items-center px-2`}
    >
      <ActionsDropdown
        placement="bottom-left"
        actions={[
          {
            label: t('First'),
            onClick: () => onPageChange?.(1),
            icon: 'fa fa-chevron-up font_12',
          },

          {
            label: t('Last'),
            icon: 'fa fa-chevron-down font_12',
            onClick: () => onPageChange?.(totalPagesCount),
          },
        ]}
        trigger={({ toggle, triggerRef }) => (
          <Button
            variant="gray300"
            ref={triggerRef}
            isBorderButton
            onClick={toggle}
            className="text-nowrap font-normal border-0 px-2 py-1 font_12"
          >
            <>
              {formatNumber((pagination.current - 1) * pagination.per_page + 1)}{' '}
              -{' '}
              {formatNumber(
                Math.min(
                  pagination.current * pagination.per_page,
                  pagination.count,
                ),
              )}{' '}
              {t('of')}{' '}
            </>
            {formatNumber(pagination.count)}
          </Button>
        )}
      ></ActionsDropdown>

      <Button
        isBorderButton
        type="button"
        disabled={prevDisabled}
        style={{
          minWidth: '40px',
          minHeight: '30px',
        }}
        onClick={() => onChange(current - 1)}
        className={` border-0 font_14 p-0 ${prevDisabled ? 'disabled' : ''}`}
        tooltip={t('prev')}
      >
        <i
          className={`fa-solid fa-chevron-left font_12 font-color-grey ${
            isArabic() ? 'mirror' : ''
          }`}
        />
      </Button>

      <Button
        isBorderButton
        type="button"
        disabled={nextDisabled}
        style={{
          minWidth: '40px',
          minHeight: '30px',
        }}
        onClick={() => onChange(current + 1)}
        className={`  border-0 font_14 p-0 ${nextDisabled ? 'disabled' : ''}`}
        tooltip={t('next')}
      >
        <i
          className={`fa-solid fa-chevron-right font_12 font-color-grey ${
            isArabic() ? 'mirror' : ''
          }`}
        />
      </Button>
    </div>
  )
}
