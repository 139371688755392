import { useLoggedIn, useLoggedInAdmin } from 'auth/useAuth'
import { ActionButtonProp } from 'components/Admin/HeaderPage_v2'
import Avatar from 'components/Avatar/Avatar'
import Button, { ButtonProps } from 'components/Button/Button'
import { EmptyCard } from 'components/Cards/LMS/EmptyLMSCards'
import Dropdown from 'components/Dropdown/Dropdown'
import { useCreateModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import Notifications from 'components/Notifications/NotificationsDropDown'
import QueryTableHeader, {
  QueryControls,
} from 'components/Query/QueryTable/QueryTableHeader'
import { QueryParams, QueryResource } from 'components/Query/useResource'
import { Select } from 'components/Select/Select'
import { SelectSearch } from 'components/Select/SelectSearch'
import { useGetSlots } from 'components/Tables/Admin/classTable'
import { useCommunityUnreadStatus } from 'core/hooks/useCommunityUnreadStatus'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom'
import { AdminsRoutesPermission } from 'routes/AdminRoutes'
import { useOrganization } from 'utils/Providers/OrganizationProvider'
import { ResourceItem } from 'utils/usePaginatedResource'

interface ColumnConfig {
  [location: string]: string[]
}
export function OrgSelect() {
  const { loggedInUser } = useLoggedIn()

  const { org, onOrgChange } = useOrganization()

  if (!loggedInUser.isAdmin || loggedInUser.organizations.length === 1) {
    return null
  }

  return (
    <div style={{ maxWidth: 'min-content' }}>
      <Select
        value={org?.id}
        label={<></>}
        clearable={false}
        options={loggedInUser.organizations?.map((o) => ({
          value: o.id,
          data: o,
          label: o.name,
        }))}
        renderTrigger={(selected) => (
          <span className=" font-semibold">{selected?.data.name}</span>
        )}
        onChange={(orgId) => orgId && onOrgChange(orgId)}
      />
    </div>
  )
}
function AdminHeaderBar() {
  const { logout } = useLoggedIn()

  return (
    <div
      className="d-flex align-items-center border-color-grey-opacity-10 bg-white justify-content-between"
      style={{ height: '56px', width: '100%' }}
    >
      <img
        style={{ maxHeight: '60%', width: 'auto', maxWidth: '240px' }}
        alt=""
        src={`/images/logo/app/en-a.png`}
        className="px-4"
      />

      <div className="d-flex justify-content-end gap-3 px-4 align-items-center ">
        <div style={{ maxWidth: '220px' }}>
          <OrgSelect />
        </div>
        <Notifications />

        <Dropdown
          label={
            <Avatar
              userImage={''}
              imageSize={40}
              iconSize={20}
              iconColor="grey"
            />
          }
        >
          <Button
            className="dropdown-item"
            onClick={logout}
            label={'Sign out'}
            isButtonLink
            variant="blue700"
          />
        </Dropdown>
      </div>
    </div>
  )
}

export function BlueButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      className="px-2 py-2 font_14 font-semibold align-items-baseline text-nowrap d-flex gap-2"
      variant="primary"
    >
      <>
        {props.icon && <i className={props.icon} />}
        {props.children}
      </>
    </Button>
  )
}

export function GreyButton() {
  return <Button></Button>
}

export function OrangeButton(props: ButtonProps) {
  return (
    <Button
      className="px-2 py-2 font_14 font-semibold align-items-baseline text-nowrap d-flex gap-2"
      {...props}
      variant="orange500"
    >
      <>
        {props.icon && <i className={props.icon} />}
        {props.children}
      </>
    </Button>
  )
}

export function RedButton() {
  return <Button></Button>
}

function AdminSideBar() {
  const { loggedInAdmin } = useLoggedInAdmin()
  const location = useLocation()
  const { hasUnreadMessages } = useCommunityUnreadStatus()
  const permissionsArray = Object.keys(loggedInAdmin.permissions)
  const allowed = AdminsRoutesPermission.filter((r) =>
    permissionsArray.includes(r.permission),
  )
  return (
    <div
      className=" flex-scroll border  px-2 border-color-grey-opacity-10 bg-white"
      style={{
        borderRight: '1px solid',
        maxWidth: '240px',
        paddingBottom: '70px',
        paddingTop: '20px',
      }}
    >
      {allowed.map((r) =>
        r.hidden ? null : (
          <AdminLink
            label={r.text || r.permission}
            to={r.link}
            key={r.link}
            showIndicator={
              r.text === 'Community' &&
              hasUnreadMessages &&
              !location.pathname.includes('community')
            }
          />
        ),
      )}
    </div>
  )
}

function AdminLink({
  to,
  label,
  showIndicator,
}: {
  to: string
  label: string
  showIndicator?: boolean
}) {
  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <div
          tabIndex={0}
          style={{ position: 'relative' }}
          className={`d-flex align-items-center font_14 font-semibold rounded ps-4 px-2 py-2 ${
            isActive
              ? 'bg-color-primary300 font-color-primary '
              : 'font-color-blue600 font-color-grey'
          }`}
        >
          {isActive && (
            <span
              className="   d-flex align-items-center"
              style={{
                position: 'absolute',
                top: '0',
                left: '0px',
                width: '100%',
                height: '100%',
              }}
            >
              <span
                className="bg-color-primary rounded"
                style={{ width: '4px', height: '10px' }}
              />
            </span>
          )}
          {label}
          {showIndicator && (
            <i className="fa fa-circle font_10 font-color-orange500 ml-auto" />
          )}
        </div>
      )}
    </NavLink>
  )
}

const TableColumnsContext = createContext<{
  hiddenColumns: string[]
  availableColumns: string[]
  initiateTableColumns: (v: string[]) => void
  setAvailableColumns: (v: string[]) => void
}>({} as any)

export function useAdminTableColumns() {
  return useContext(TableColumnsContext)
}

export function AdminPage<P extends QueryParams, T extends ResourceItem>({
  title,
  children,
  showOrgName,
  buttons,
  actions = [],
  exportFn,
  query,
}: {
  title: ReactNode
  children: ReactNode
  showOrgName?: boolean
  buttons?: ReactNode
  query?: QueryResource<P, T>
  actions?: ActionButtonProp[]
  exportFn?: () => void
}) {
  const { org } = useOrganization()
  const { dashboard } = useLoggedIn()
  const location = useLocation()
  const currentPath = location.pathname

  const [showReport, setShowReport] = useState(false)

  const { data: slots } = useGetSlots()

  // Regular expression to extract base URL
  const regex = /\/[^/]+/
  const match = currentPath.replace(dashboard, '').match(regex)

  // Extracting base URL and omitting localhost part
  let baseUrl = ''
  if (match) {
    baseUrl = dashboard + match[0]
  }

  const [availableColumns, setAvailableColumns] = useState<string[]>([])
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([])

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const storageKey = 'hidden_columns'
    const currentPath = location.pathname

    // Retrieve current configuration
    const storedConfig = localStorage.getItem(storageKey)
    let columnConfig: ColumnConfig = {}

    if (storedConfig) {
      try {
        columnConfig = JSON.parse(storedConfig)
      } catch (error) {
        console.error('Error parsing stored column configuration:', error)
        localStorage.removeItem(storageKey)
      }
    }

    // Update configuration for current path
    columnConfig[currentPath] = newHiddenColumns

    // Save updated configuration
    localStorage.setItem(storageKey, JSON.stringify(columnConfig))

    // Update state
    setHiddenColumns(newHiddenColumns)
  }

  function getHiddenColumns(): string[] {
    const storageKey = 'hidden_columns'
    const currentPath = location.pathname

    const storedConfig = localStorage.getItem(storageKey)
    if (storedConfig) {
      try {
        const columnConfig: ColumnConfig = JSON.parse(storedConfig)
        return columnConfig[currentPath] || []
      } catch (error) {
        console.error('Error parsing stored column configuration:', error)
      }
    }

    return []
  }

  useEffect(() => {
    const initialHiddenColumns = getHiddenColumns()
    setHiddenColumns(initialHiddenColumns)
  }, [])

  const ColumnsModal = useCreateModal()
  const [search, setSearch] = useState('')

  const list = availableColumns
    .filter((c) => !!c)
    .filter((c) =>
      search ? c.toLowerCase().includes(search.toLowerCase()) : true,
    )
  return (
    <div className="flex-scroll border bg-color-primary300-opacity-10">
      <ColumnsModal.Container>
        <ModalWindow
          titleClassName="p-3"
          headerClassName="p-0"
          bodyClassName="p-0"
          title={
            <div>
              Edit Table Columns Visibility
              <div className="py-2 font_14 font-color-blue300">
                {availableColumns.length - hiddenColumns.length} /{' '}
                {availableColumns.length} columns
              </div>
            </div>
          }
        >
          <div className="flex-scroll gap-1" style={{ height: '600px' }}>
            <div className="border-b px-2 gap-2 py-2 d-flex border-color-grey-opacity-10">
              <Button
                tooltip="Toggle All"
                isBorderButton
                onClick={() => {
                  updateHiddenColumns(
                    hiddenColumns.length == availableColumns.length
                      ? []
                      : availableColumns,
                  )
                }}
                className="p-2 border-0"
              >
                <i
                  className={` font_20  ${
                    hiddenColumns.length == availableColumns.length
                      ? 'fa-regular fa-square font-color-blue300-opacity-10'
                      : 'fa fa-square-check font-color-primary'
                  }`}
                />
              </Button>
              <SelectSearch
                className="border-0"
                onChange={setSearch}
                value={search}
              />
            </div>
            <div className="flex-scroll gap-2">
              {list.map((column) => (
                <div
                  key={column}
                  className="d-flex gap-3 align-items-center p-3 border-b border-color-grey-opacity-10 cursor-pointer hover-primary300"
                  onClick={() => {
                    const newHiddenColumns = hiddenColumns?.includes(column)
                      ? hiddenColumns.filter((col) => col !== column)
                      : [...hiddenColumns, column]
                    updateHiddenColumns(newHiddenColumns)
                  }}
                >
                  <i
                    className={` font_20  ${
                      hiddenColumns?.includes(column)
                        ? 'fa-regular fa-square font-color-blue300-opacity-10'
                        : 'fa fa-square-check font-color-primary'
                    }`}
                  />
                  <span className="font-color-blue300 font-semibold">
                    {column}
                  </span>
                </div>
              ))}
              {list.length == 0 && <EmptyCard />}
            </div>
          </div>
        </ModalWindow>
      </ColumnsModal.Container>
      <TableColumnsContext.Provider
        value={{
          hiddenColumns,
          // setHiddenColumns,
          availableColumns,
          setAvailableColumns,
          initiateTableColumns: setAvailableColumns,
        }}
      >
        <div
          style={{ minHeight: '50px' }}
          className="px-3 font_16 gap-2 align-items-center d-flex  border-b border-color-grey-opacity-10  bg-white font-bold text-start m-0 "
        >
          <Link to={baseUrl}>
            {showOrgName && org?.name ? `${org.name} ` : ''}
            {title}
          </Link>
          {buttons}
          <QueryControls
            query={query}
            exportFn={exportFn}
            actions={actions}
            showReport={showReport}
            onColumnsConfigClick={ColumnsModal.openModal}
            onReportClick={() => setShowReport((_) => !_)}
          />
        </div>

        {query?.filtersConfig && !showReport && (
          <QueryTableHeader query={query} />
        )}
        <div className="  flex-scroll">
          {showReport ? (
            <iframe
              className="p-2"
              src={query?.data?.report_url}
              width="100%"
              height="100%"
            />
          ) : (
            children
          )}
        </div>
      </TableColumnsContext.Provider>
    </div>
  )
}

export default function AdminDashboard() {
  return (
    <div
      className="flex-scroll overlfow-auto bg-color-primary300-opacity-50"
      style={{ height: '100vh', width: '100vw' }}
    >
      <AdminHeaderBar />
      <div className="flex-scroll flex-row " style={{}}>
        <AdminSideBar />

        <div className="bg-primary300 flex-scroll ">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
