import { addAdminRole } from 'auth/AddUserRole'
import { AxiosResponse } from 'axios'
import { Response } from 'core/types/Response'
import { Admin } from 'core/types/User'
import { AdminAPI } from 'services/API'
import { extractResponseData } from 'services/utils'

function mapStudentRepsonse(resp: AxiosResponse<Response<Admin>>): Admin {
  const admin = addAdminRole(extractResponseData(resp))

  return {
    ...admin,
    organizations: admin.organizations.sort((a, b) => a.id - b.id),
  }
}

export function adminLogin(email: string, password: string) {
  return AdminAPI.post<Response<Admin>>('/login', {
    email,
    password,
  }).then(mapStudentRepsonse)
}

export function getMyAdminProfile() {
  return AdminAPI.get<Response<Admin>>('/profile').then(mapStudentRepsonse)
}
